import React, { useContext } from "react";
import orderimage from "../../assets/disposableassets/orderimage.png";
import Button from "../button/Button";
import { Link } from "react-router-dom";
import { ContextData } from "../../context/Context";
import LazyLoad from "react-lazyload";

function OrderCard({
  id,
  orderid,
  orderDate,
  orderAmount,
  orderPujaName,
  orderAddons,
  orderAddress,
  orderPujaTime,
  orderDuration,
}) {
  const { orderlist, setorderdetail } = useContext(ContextData);

  const handleOrderDetail = (id) => {
    const detail = orderlist.find((order) => order.id === id);
    setorderdetail(detail);
  };

  return (
    <div className="border rounded-[10px] flex flex-col justify-between bg-[#fff1cf]">
      <div className="px-8 py-4 border rounded-[10px]">
        <div className="flex flex-row max-md:flex-col max-md:gap-4 justify-between items-center">
          <div className="flex flex-row gap-16">
            <div className="flex flex-col gap-2">
              <span className="font-inter text-[16px] max-sm:text-[12px] font-semibold text-[#000000]">
                ORDER PLACED
              </span>
              <span className="font-inter text-[16px] max-sm:text-[12px] font-normal text-[#000000]">
                {orderDate}
              </span>
            </div>
            <div className="flex flex-col gap-2">
              <span className="font-inter text-[16px] max-sm:text-[12px] font-semibold text-[#000000]">
                TOTAL
              </span>
              <span className="font-inter text-[16px] max-sm:text-[12px] font-normal text-[#000000]">
                {orderAmount}
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <span className="font-inter text-[16px] max-sm:text-[12px] font-semibold text-[#000000]">
              {orderid}
            </span>
            <div className="flex flex-row items-center gap-4">
              <Link to="/orderdetail" onClick={() => handleOrderDetail(id)}>
                <button className="font-inter text-[16px] max-sm:text-[12px] font-bold text-[#9747FF]">
                  View Order Details
                </button>
              </Link>
              <button className="font-inter text-[16px] max-sm:text-[12px] font-bold text-[#9747FF]">
                Download Invoice
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="px-8 py-4 flex flex-col gap-2">
        <span className="font-inter text-[20px] max-sm:text-[16px] font-bold text-[#000000]">
          Puja on Saturday
        </span>
        <span className="font-inter text-[16px] max-sm:text-[12px] font-bold text-[#067841] mb-4">
          Preparing for Puja
        </span>
        <div className="flex flex-row max-lg:flex-col justify-between gap-8">
          <LazyLoad height={200}>
            <img
              src={orderimage}
              alt={orderPujaName}
              className="w-[250px] h-[200px] rounded-[10px] flex flex-1"
              style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
            />
          </LazyLoad>
          <div className="flex flex-col gap-2 flex-1">
            <span className="font-inter text-[20px] max-sm:text-[16px] font-bold text-[#643EF9]">
              {orderPujaName}
            </span>
            <div className="flex flex-row gap-2 items-center">
              <span className="font-inter text-[16px] max-sm:text-[12px] font-medium text-[#000000]">
                Add Ons:
              </span>
              <span className="font-inter text-[16px] max-sm:text-[12px] font-medium text-[#643EF9]">
                {orderAddons}
              </span>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <span className="font-inter text-[16px] max-sm:text-[12px] font-medium text-[#000000]">
                Address:
              </span>
              <span className="font-inter text-[16px] max-sm:text-[12px] font-medium text-[#643EF9]">
                {orderAddress}
              </span>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <span className="font-inter text-[16px] max-sm:text-[12px] font-medium text-[#000000]">
                Puja Time:
              </span>
              <span className="font-inter text-[16px] max-sm:text-[12px] font-medium text-[#643EF9]">
                {orderPujaTime}
              </span>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <span className="font-inter text-[16px] max-sm:text-[12px] font-medium text-[#000000]">
                Duration:
              </span>
              <span className="font-inter text-[16px] max-sm:text-[12px] font-medium text-[#643EF9]">
                {orderDuration}
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <Button
              label="Contact RitiVidhi"
              style={{
                backgroundColor: "transparent",
                color: "black",
                borderColor: "black",
              }}
            />
            <Button
              label="Cancel the Booking"
              style={{
                backgroundColor: "transparent",
                color: "black",
                borderColor: "black",
              }}
            />
            <Button
              label="Puja Guide/FAQs"
              style={{
                backgroundColor: "transparent",
                color: "black",
                borderColor: "black",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderCard;
