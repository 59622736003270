import React from "react";

import AstrologyBanner from "../../assets/AstrologyBanner.jpg";
import AstrologyImage from "../../assets/AstrologyImage.jpg";
import Footer from "../../components/footer/Footer";

function AstrologyPage() {
  return (
    <div className="bg-[#fff3f2] min-h-screen flex flex-col items-center text-center py-10 px-4">
      <div className="w-full max-w-5xl mb-10">
        <img
          src={AstrologyBanner}
          alt="AstrologyBanner"
          className="w-full h-64 object-cover rounded-lg"
        />
      </div>

      <header className="max-w-2xl mb-10">
        <h1 className="text-2xl font-semibold text-gray-700 mb-2">
          Unlock Life’s Mysteries. Gain deeper insights into your future with
          our all-encompassing Astrology 360-Degree Service
        </h1>
        <button className="mt-4 px-6 py-2 bg-yellow-500 text-white font-semibold rounded-full">
          Get Prediction
        </button>
      </header>

      <main className="mt-10 max-w-5xl w-full grid grid-cols-1 lg:grid-cols-2 gap-10">
        <div className="flex justify-center">
          <img
            src={AstrologyImage}
            alt="AstrologyImage"
            className="w-64 h-64 object-cover rounded-full border border-gray-300"
          />
        </div>

        <div className="text-left">
          <h2 className="text-2xl font-semibold text-gray-700 mb-4">
            Looking for Answers?
          </h2>
          <p className="text-gray-600 mb-6">
            Struggling for direction? We’re here to illuminate your path. Our
            expert astrologers delve into the stars, uncovering truths that
            empower your journey. With areas covering Career & Profession,
            Health, Wealth, Relationship, and more, we provide clarity on life’s
            big questions using age-old and practical methods tailored just for
            you.
          </p>
          <h3 className="font-semibold mb-2">Feature Benefits</h3>
          <ul className="list-disc list-inside space-y-1 text-gray-600">
            <li>Career & Professional Growth</li>
            <li>Health & Well-being</li>
            <li>Relationship & Love</li>
            <li>Wealth & Financial Stability</li>
            <li>Spiritual Growth</li>
          </ul>
        </div>
      </main>

      <section className="mt-10 max-w-5xl">
        <h3 className="text-xl font-semibold text-gray-700 mb-4">
          Choose Your Package for Maximum Impact
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-[#ffe0e0] p-6 rounded-lg shadow-lg">
            <h4 className="text-lg font-semibold mb-2">Single Package</h4>
            <ul className="list-disc list-inside text-gray-600 space-y-1">
              <li>Personalized insights into personal growth</li>
              <li>Health and wellness guidance</li>
              <li>Basic financial stability advice</li>
            </ul>
            <button className="mt-4 px-4 py-2 bg-yellow-500 text-white font-semibold rounded-full">
              Select package for Rs. 1000.00
            </button>
          </div>

          <div className="bg-[#ffe0e0] p-6 rounded-lg shadow-lg">
            <h4 className="text-lg font-semibold mb-2">Couples Package</h4>
            <ul className="list-disc list-inside text-gray-600 space-y-1">
              <li>Relationship compatibility insights</li>
              <li>Joint financial stability guidance</li>
              <li>Health and wellness for couples</li>
            </ul>
            <button className="mt-4 px-4 py-2 bg-yellow-500 text-white font-semibold rounded-full">
              Select package for Rs. 2000.00
            </button>
          </div>

          <div className="bg-[#ffe0e0] p-6 rounded-lg shadow-lg">
            <h4 className="text-lg font-semibold mb-2">Family Package</h4>
            <ul className="list-disc list-inside text-gray-600 space-y-1">
              <li>Insights into family growth and harmony</li>
              <li>Comprehensive health and wellness advice</li>
              <li>Financial stability and planning</li>
            </ul>
            <button className="mt-4 px-4 py-2 bg-yellow-500 text-white font-semibold rounded-full">
              Select package for Rs. 3000.00
            </button>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default AstrologyPage;
