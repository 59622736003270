import React from "react";

function AboutGroupPuja() {
  return (
    <div className="px-[8%] pt-24 max-md:pt-36 max-sm:pt-20">
      <h1 className="text-[20px] font-bold font-inter text-[#00000] mb-10">
        Shaktipeeth Kolhapur Ambabai Special 11,000 Mahalakshmi Mantra Jaap,
        Vaibhav Lakshmi Puja and Havan to get Blessings for Abundance of Wealth
        and Bliss in Life
      </h1>
      <div className="text-[18px] font-normal font-inter text-[#00000]">
        Experience the divine blessings of Goddess Mahalakshmi with the Special
        11,000 Mahalakshmi Mantra Jaap at the sacred Shaktipeeth Kolhapur
        Ambabai Temple. This powerful spiritual ritual, accompanied by the
        Vaibhav Lakshmi Puja and Havan, invokes the goddess of wealth and
        prosperity to shower your life with abundance, success, and bliss.
        <br />
        <br />
        Mahalakshmi, the deity of wealth and fortune, holds the key to unlocking
        prosperity and harmony. Through this sacred Jaap, Puja, and Havan,
        performed according to the ancient Vedic traditions, devotees seek her
        blessings for:
        <br />
        <br />
        <ul style={{ listStyleType: "disc", paddingLeft: "1.5rem" }}>
          <li>Abundance of wealth: Attract financial stability and growth.</li>
          <li>
            Success and prosperity: Overcome obstacles and invite success in all
            endeavors.
          </li>
          <li>
            Happiness and peace: Embrace a life filled with joy, harmony, and
            contentment.
          </li>
        </ul>
        <br />
        <br />
        The ritual includes the recitation of 11,000 Mahalakshmi mantras,
        invoking the goddess's energy to fill your life with material and
        spiritual wealth. The Vaibhav Lakshmi Puja, dedicated to Goddess Lakshmi
        in her most benevolent form, helps ensure prosperity in every aspect of
        life, while the Havan purifies the environment and invokes divine
        energy.
        <br />
        <br />
        Join us for this powerful and auspicious event at Shaktipeeth Kolhapur
        and receive the divine blessings of Goddess Mahalakshmi for a
        prosperous, blissful, and harmonious life.
      </div>
    </div>
  );
}

export default AboutGroupPuja;
