import React from "react";
import ritividhilogo from "../../../assets/ritividhilogo.png";
import LazyLoad from "react-lazyload";

function Logo() {
  return (
    <div className="flex flex-col items-center gap-4 max-lg:gap-5">
      <LazyLoad height={50}>
        <img
          src={ritividhilogo}
          alt="ritividhilogo"
          className="size-28 max-lg:size-20 max-sm:size-16"
        />
      </LazyLoad>
      <span className="text-[#000000] text-[32px] max-lg:text-[24px] max-md:text-[18px] max-sm:text-[16px] font-normal non-italic font-sans flex flex-wrap">
        आचार्यानुसारं पूजनम्,गृहद्वारम् एव।
      </span>
    </div>
  );
}

export default Logo;
