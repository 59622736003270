import React from "react";

function InfiniteAnimationRight() {
  return (
    <div className="flex space-x-8 max-sm:space-x-4 overflow-hidden bg-[#BFD4F94D] py-8 mt-5">
      <div className="flex space-x-8 max-sm:space-x-4 animate-scrollright whitespace-nowrap">
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Tradition
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Authenticity
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Heritage
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Depth
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Sacred
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Unity
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Ritualistic
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Consistency
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Cultural Identity
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Spirituality
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Wisdom
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Timelessness
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Sanctity
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Continuity
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Reverence
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Formality
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Collective Memory
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Ritualistic Precision
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Ancestral Connection
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Cultural Preservation
        </span>
      </div>

      <div
        className="flex space-x-8 max-sm:space-x-4 animate-scrollright whitespace-nowrap"
        aria-hidden="true"
      >
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Tradition
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Authenticity
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Heritage
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Depth
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Sacred
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Unity
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Ritualistic
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Consistency
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Cultural Identity
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Spirituality
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Wisdom
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Timelessness
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Sanctity
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Continuity
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Reverence
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Formality
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Collective Memory
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Ritualistic Precision
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Ancestral Connection
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#A6C2F5] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Cultural Preservation
        </span>
      </div>
    </div>
  );
}

export default InfiniteAnimationRight;
