import React, { useEffect } from "react";
import HeroImage from "./sections/HeroImage";
import BookPuja from "./sections/BookPuja";
import Services from "./sections/Services";
import AllPujas from "./sections/AllPujas";
import TempleServices from "./sections/TempleServices";
import Blogs from "./sections/Blogs";
import About from "./sections/About";
import CorporateServices from "./sections/CorporateServices";
import SpiritualMeditation from "./sections/SpiritualMeditation";
import AstrologyServices from "./sections/AstrologyServices";
import Subscribe from "./sections/Subscribe";
import SearchFilter from "../../components/searchFilter/SearchFilter";
import InfiniteAnimationRight from "../../components/infiniteanimation/InfiniteAnimationRight";
import InfiniteAnimationLeft from "../../components/infiniteanimation/InfiniteAnimationLeft";
import Footer from "../../components/footer/Footer";

function LandingPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SearchFilter />
      <HeroImage />
      <BookPuja />
      <Services />
      <InfiniteAnimationRight />
      <div className="flex flex-row w-full justify-center gap-8 py-4">
        <span className="text-[#6d9df2] text-[24px] max-md:text-[20px] max-sm:text-[16px] font-bold font-sans">
          Legacy
        </span>
        <span className="text-[#6F6F6F] text-[24px] max-md:text-[20px] max-sm:text-[16px] font-bold font-sans">
          Present
        </span>
      </div>
      <InfiniteAnimationLeft />
      <AllPujas />
      <TempleServices />
      <Blogs />
      <About />
      {/*<CorporateServices />*/}
      <Subscribe />
      {/*<SpiritualMeditation />
      <AstrologyServices />*/}
      <Footer />
    </>
  );
}

export default LandingPage;
