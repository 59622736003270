import React, { useContext } from "react";
import Heading from "../../../components/cards/Heading";
import LandingPageMainCard from "../../../components/cards/LandingPageMainCard";
import LandingPageCard from "../../../components/cards/LandingPageCard";
import Button from "../../../components/button/Button";
import { Link, useNavigate } from "react-router-dom";
import { ContextData } from "../../../context/Context";

function AllPujas() {
  let buttonName = "All Pujas";
  let headingdescription = "Explore Our Complete Puja Rituals List";

  const { pujasummary, setCurrentPuja, setCurrentpujadetail } =
    useContext(ContextData);

  const navigate = useNavigate();

  const handlepuja = async (pujaId, pujaImageURL) => {
    setCurrentPuja(1);
    try {
      const response = await fetch(
        "https://app.ritividhi.com/ritividhi/offline-puja"
      );
      const currentpuja = await response.json();
      const currentpujadata = currentpuja.filter(
        (puja) => puja.pujaId === pujaId
      );

      if (currentpujadata.length > 0) {
        const [pujaDetails] = currentpujadata;
        const updatedAddOns = pujaDetails.addOns.map((addOn) => ({
          ...addOn,
          quantity: 1,
        }));

        const pujadetailWithImage = {
          ...pujaDetails,
          pujaImageURL,
          addOns: updatedAddOns,
        };
        setCurrentpujadetail([pujadetailWithImage]);
      }
      navigate("/product");
    } catch (error) {
      console.error("Error fetching all pujas summary", error);
    }
  };

  return (
    <>
      <div className="px-[8%] py-10">
        <div className="flex flex-col gap-4 max-lg:hidden">
          <div className="flex flex-row gap-8">
            <Heading {...{ buttonName, headingdescription }} />
            <>
              {pujasummary.slice(0, 1).map((puja) => {
                const {
                  pujaId,
                  name,
                  pujaImageURL,
                  description,
                  buttonlabel = "Book Now",
                  time,
                } = puja;
                return (
                  <div key={pujaId}>
                    <LandingPageMainCard
                      {...{
                        name,
                        pujaImageURL,
                        description,
                        buttonlabel,
                        time,
                      }}
                      onClick={() => handlepuja(pujaId, pujaImageURL)}
                    />
                  </div>
                );
              })}
            </>
          </div>
          <div className="grid grid-cols-3 gap-4">
            {pujasummary.slice(1, 4).map((puja) => {
              const {
                pujaId,
                name,
                pujaImageURL,
                description,
                buttonlabel = "Book Now",
                time,
              } = puja;
              return (
                <div key={pujaId}>
                  <LandingPageCard
                    {...{ name, pujaImageURL, description, buttonlabel, time }}
                    onClick={() => handlepuja(pujaId, pujaImageURL)}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className="hidden max-lg:flex max-lg:flex-col max-lg:gap-4">
          <Heading {...{ buttonName, headingdescription }} />
          <div className="grid grid-cols-2 gap-4">
            {pujasummary.slice(0, 4).map((puja) => {
              const {
                pujaId,
                name,
                pujaImageURL,
                description,
                buttonlabel = "Book Now",
                time,
              } = puja;
              return (
                <div
                  key={pujaId}
                  onClick={() => handlepuja(pujaId, pujaImageURL)}
                >
                  <LandingPageCard
                    {...{ name, pujaImageURL, description, buttonlabel, time }}
                    onClick={() => handlepuja(pujaId, pujaImageURL)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="py-4 px-[8%] bg-[#FBD16E] flex flex-row items-center gap-16 max-lg:hidden">
        <p className="text-[#000000] font-sans non-italic font-semibold">
          Looking for something else? Click 'View All' to explore more Pujas.
        </p>
        <Link to="/product">
          <Button
            label="View complete puja list"
            style={{
              backgroundColor: "#FBD16E",
              color: "black",
              borderColor: "#FF8213",
            }}
          />
        </Link>
      </div>
      <div className="hidden max-lg:flex max-lg:w-full max-lg:justify-center">
        <Link to="/product">
          <Button
            label="View All"
            style={{
              backgroundColor: "#FBD16E",
              color: "black",
              borderColor: "#FFF1D0",
            }}
          />
        </Link>
      </div>
    </>
  );
}

export default AllPujas;
