import React, { useContext } from "react";
import ProgressSteps from "../../components/progresssteps/ProgressSteps";
import { ContextData } from "../../context/Context";
import SearchCategory from "./sections/category/sections/SearchCategory";

function ProductPage() {
  const { currentpuja } = useContext(ContextData);
  return (
    <>
      <div>{currentpuja === 0 ? <SearchCategory /> : null}</div>
      <ProgressSteps />
    </>
  );
}

export default ProductPage;
