import React from "react";
import Button from "../../../components/button/Button";
import LandingPageCard from "../../../components/cards/LandingPageCard";
import LandingPageMainCard from "../../../components/cards/LandingPageMainCard";
import Heading from "../../../components/cards/Heading";
import allpujaimage1 from "../../../assets/disposableassets/allpujaimage1.png";
import allpujaimage2 from "../../../assets/disposableassets/allpujaimage2.png";
import allpujaimage3 from "../../../assets/disposableassets/allpujaimage3.png";
import allpujaimage4 from "../../../assets/disposableassets/allpujaimage4.png";
import { Link } from "react-router-dom";

function Blogs() {
  let buttonName = "Learn/ Read Blogs";
  let headingdescription =
    "Discover Insightful Blogs for Spiritual Growth and Inner Peace";

  const pujacardlist = [
    {
      id: 1,
      heading: "Lorem ipsum dolor",
      description:
        "Lorem ipsum dolor sit amet consectetur. At in commodo aliquam venenatis. ",
      buttonlabel: "Read Now",
      image: allpujaimage1,
      time: "10 Mins Read",
    },
    {
      id: 2,
      heading: "Lorem ipsum dolor",
      description:
        "Lorem ipsum dolor sit amet consectetur. At in commodo aliquam venenatis. ",
      buttonlabel: "Read Now",
      image: allpujaimage2,
      time: "10 Mins Read",
    },
    {
      id: 3,
      heading: "Lorem ipsum dolor",
      description:
        "Lorem ipsum dolor sit amet consectetur. At in commodo aliquam venenatis. ",
      buttonlabel: "Read Now",
      image: allpujaimage3,
      time: "10 Mins Read",
    },
    {
      id: 4,
      heading: "Lorem ipsum dolor",
      description:
        "Lorem ipsum dolor sit amet consectetur. At in commodo aliquam venenatis. ",
      buttonlabel: "Read Now",
      image: allpujaimage4,
      time: "10 Mins Read",
    },
  ];
  return (
    <>
      <div className="px-[8%] py-10">
        <div className="flex flex-col gap-8 max-lg:hidden">
          <div className="flex flex-row gap-4">
            <Heading {...{ buttonName, headingdescription }} />
            <>
              {pujacardlist.slice(0, 1).map((puja) => {
                const { id, heading, description, buttonlabel, image, time } =
                  puja;
                return (
                  <div key={id}>
                    <LandingPageMainCard
                      name={heading}
                      pujaImageURL={image}
                      description={description}
                      buttonlabel={buttonlabel}
                      time={time}
                    />
                  </div>
                );
              })}
            </>
          </div>
          <div className="grid grid-cols-3 gap-4">
            {pujacardlist.slice(1, pujacardlist.length).map((puja) => {
              const { id, heading, description, buttonlabel, image, time } =
                puja;
              return (
                <div key={id}>
                  <LandingPageCard
                    name={heading}
                    pujaImageURL={image}
                    description={description}
                    buttonlabel={buttonlabel}
                    time={time}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className="hidden max-lg:flex max-lg:flex-col max-lg:gap-8">
          <Heading {...{ buttonName, headingdescription }} />
          <div className="grid grid-cols-2 gap-4">
            {pujacardlist.slice(0, pujacardlist.length).map((puja) => {
              const { id, heading, description, buttonlabel, image, time } =
                puja;
              return (
                <div key={id}>
                  <LandingPageCard
                    name={heading}
                    pujaImageURL={image}
                    description={description}
                    buttonlabel={buttonlabel}
                    time={time}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="py-4 px-[8%] bg-[#FBD16E] flex flex-row items-center gap-16 max-lg:hidden">
        <p className="text-[#000000] font-sans non-italic font-semibold">
          Looking for something else? Click 'View All' to explore more Pujas.
        </p>
        <Link to="/blog">
          <Button
            label="Read our blogs"
            style={{
              backgroundColor: "#FBD16E",
              color: "black",
              borderColor: "#FF8213",
            }}
          />
        </Link>
      </div>
      <div className="hidden max-lg:flex max-lg:w-full max-lg:justify-center">
        <Link to="/blog">
          <Button
            label="View All"
            style={{
              backgroundColor: "#FBD16E",
              color: "black",
              borderColor: "#FFF1D0",
            }}
          />
        </Link>
      </div>
    </>
  );
}

export default Blogs;
