import React from "react";
import Button from "../button/Button";

function ServiceHeading() {
  return (
    <div className="flex flex-col gap-8">
      <Button
        label="Services"
        style={{
          backgroundColor: "#E2D1F9",
          color: "black",
          borderColor: "#000000",
        }}
      />
      <p className="font-sans text-[32px] not-italic font-bold leading-[43px] text-[#000000]">
        Discover Our Personalized Pooja Services, Crafted Just for You
      </p>
    </div>
  );
}

export default ServiceHeading;
