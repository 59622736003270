import React from "react";
import { Link } from "react-router-dom";

function Categories() {
  const filterlist = [
    {
      id: 1,
      filterName: "Pooja",
      link: "/product",
    },
    {
      id: 2,
      filterName: "Temple Services",
      link: "/temple",
    },
    {
      id: 3,
      filterName: "Live Events",
      link: "/group",
    },
    {
      id: 4,
      filterName: "Read",
      link: "/blog",
    },
  ];
  return (
    <div className="flex flex-row justify-between items-start gap-4">
      {filterlist.map((filteritem) => (
        <Link to={filteritem.link} key={filteritem.id}>
          <button
            key={filteritem.id}
            className="text-[#6F6F6F] border border-[#000000] font-quicksand text-[12px] not-italic leading-normal font-semibold px-4 py-2 rounded-[20px] bg-[#FFF1D0]"
          >
            {filteritem.filterName}
          </button>
        </Link>
      ))}
    </div>
  );
}

export default Categories;
