import React, { useContext } from "react";
import Button from "../../../components/button/Button";
import { Link } from "react-router-dom";
import { ContextData } from "../../../context/Context";

function PoojaRituals() {
  const { posts } = useContext(ContextData);

  return (
    <div className="px-[8%] py-10 flex flex-col gap-4">
      <h1 className="text-[#000000] text-[32px] max-md:text-[26px] max-sm:text-[20px] font-bold font-sans">
        Pooja Rituals
      </h1>
      <p className="text-[#000000] text-[16px] font-normal font-sans mb-10">
        Discover the articles that resonate the most with our spiritual
        community. From timeless rituals to modern spiritual practices, these
        cherished reads offer wisdom, inspiration, and guidance for your
        spiritual journey.
      </p>
      <div className="grid grid-cols-4 max-md:grid-cols-2 gap-8 mb-10">
        {posts.map((blog) => {
          const { imageurl, text } = blog;
          return (
            <Link to="/blogview">
              <div className="flex flex-col gap-0">
                <img src={imageurl} alt={text} className="rounded-t-[10px]" />
                <span className="w-full flex justify-center bg-[#FFD981] py-1 px-4 rounded-b-[10px]">
                  {text}
                </span>
              </div>
            </Link>
          );
        })}
      </div>
      <div className="flex w-full justify-center">
        <Button
          label="View All"
          style={{
            backgroundColor: "#FFF1D0",
            color: "black",
            borderColor: "#000000",
          }}
        />
      </div>
    </div>
  );
}

export default PoojaRituals;
