import React, { useContext } from "react";
import namaste from "../../assets/namaste.png";
import profilepic from "../../assets/profilepic.png";
import email from "../../assets/email.svg";
import phone from "../../assets/phone.svg";
import order from "../../assets/order.svg";
import UserProfileForm from "../../components/profileform/UserProfileForm";
import Footer from "../../components/footer/Footer";
import { ContextData } from "../../context/Context";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import logout from "../../assets/logout.svg";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/setup";

function UserProfile() {
  const { setLoggedIn } = useContext(ContextData);

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        setLoggedIn(false);
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };
  return (
    <>
      <div className="px-[8%] pb-10 flex flex-col gap-10">
        <Breadcrumb
          items={[
            {
              title: (
                <a
                  href="/"
                  className="font-inter font-medium text-[14px] text-[#000000]"
                >
                  Home
                </a>
              ),
            },
            {
              title: (
                <a
                  href="/user"
                  className="font-inter font-medium text-[14px] text-[#000000]"
                >
                  Profile
                </a>
              ),
            },
          ]}
          className="mt-5"
        />
        <div className="w-full flex justify-center">
          <img src={namaste} alt="namaste" />
        </div>
        <div className="flex flex-row max-lg:flex-col max-lg:items-start max-md:items-center justify-between items-center gap-10 -mt-32 max-md:-mt-20">
          <div className="w-full flex flex-row max-sm:flex-col max-sm:items-center items-end gap-4">
            <img
              src={profilepic}
              alt="profilepic"
              className="size-40 rounded-full"
            />
            <div className="flex flex-col items-start gap-4">
              <span className="font-inter text-[24px] text-[#000000] font-bold">
                Abhishek Kumar Shukla
              </span>
              <div className="flex flex-row max-md:flex-col max-md:items-start items-center gap-8">
                <div className="flex flex-row items-center gap-4">
                  <img src={email} alt="email" className="size-6" />
                  <span className="font-inter text-[16px] text-[#000000] font-normal">
                    Abhishek97.edu@gmail.com
                  </span>
                </div>
                <div className="flex flex-row items-center gap-4">
                  <img src={phone} alt="phone" className="size-6" />
                  <span className="font-inter text-[16px] text-[#000000] font-normal">
                    9651698333
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start gap-4">
            <Link to="/order">
              <div className="flex flex-row items-center gap-4">
                <img src={order} alt="order" />
                <span className="font-inter text-[16px] text-[#000000] font-normal no-wrap shrink-0">
                  Order History/ Updates
                </span>
              </div>
            </Link>
            <Link to="/" onClick={handleSignOut}>
              <div className="flex flex-row items-center gap-4">
                <img src={logout} alt="logout" className="size-8" />
                <span className="font-inter text-[16px] text-[#000000] font-normal no-wrap shrink-0">
                  Log Out
                </span>
              </div>
            </Link>
          </div>
        </div>
        <UserProfileForm />
      </div>
      <Footer />
    </>
  );
}

export default UserProfile;
