import React, { useContext } from "react";
import { ContextData } from "../../../../../context/Context";
import orderimage from "../../../../../assets/disposableassets/orderimage.png";

function PujaSummaryCard() {
  const { orderdetail } = useContext(ContextData);

  return (
    <div className="border border-[#D9D9D9] rounded-[10px] px-8 py-4 flex flex-col gap-8 bg-[#fff1cf]">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col gap-4">
          <span className="text-[20px] max-md:text-[16px] font-inter text-[#000000] font-bold">
            Puja Summary
          </span>
          <span className="text-[16px] max-md:text-[12px] font-inter text-[#000000] font-semibold">
            Puja on Saturday
          </span>
        </div>
        <div className="flex flex-col gap-4">
          <span className="text-[16px] max-md:text-[12px] font-inter text-[#000000] font-semibold">
            Order Status
          </span>
          <span className="text-[16px] max-md:text-[12px] font-inter text-[#067841] font-semibold">
            Preparing for Puja
          </span>
        </div>
      </div>
      <div className="flex flex-row max-md:flex-col gap-8">
        <div>
          <img
            src={orderimage}
            alt={orderdetail.orderPujaName}
            className="w-[250px] h-[200px] rounded-[10px] flex flex-1"
            style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
          />
        </div>
        <div className="flex flex-col gap-2 flex-1">
          <span className="font-inter text-[20px] max-md:text-[16px] font-bold text-[#643EF9]">
            {orderdetail.orderPujaName}
          </span>
          <div className="flex flex-row gap-2 items-center">
            <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#000000]">
              Add Ons:
            </span>
            <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#643EF9]">
              {orderdetail.orderAddons}
            </span>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#000000]">
              Puja Mode:
            </span>
            <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#643EF9]">
              {orderdetail.pujaMode}
            </span>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#000000]">
              Package:
            </span>
            <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#643EF9]">
              {orderdetail.package}
            </span>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#000000]">
              Special Requirement:
            </span>
            <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#643EF9]">
              {orderdetail.specialRequirement}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PujaSummaryCard;
