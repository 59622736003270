import React, { useContext } from "react";
import { ContextData } from "../../../../../context/Context";

function PackageSelection({ pujaId, packagedetail }) {
  const { selectedmode, cartpujadata, setCartPujaData, setselectedpackage } =
    useContext(ContextData);

  const { packageId, name, packagePrice } = packagedetail;

  const handleselectedpackage = () => {
    const updatedcartpackage = cartpujadata.map((cartpackage) => {
      if (cartpackage.pujaId === pujaId) {
        return {
          ...cartpackage,
          homePuja: packagedetail,
        };
      }
    });
    setselectedpackage(packagedetail);
    setCartPujaData(updatedcartpackage);
  };

  const isSelected = cartpujadata.some(
    (cartpackage) =>
      cartpackage.pujaId === pujaId &&
      cartpackage.homePuja.packageId === packageId
  );

  return (
    <>
      {selectedmode ? (
        <>
          {selectedmode === "Online" ? (
            <div className="w-full flex flex-col justify-between my-4 gap-4 cursor-pointer">
              <div
                key={packageId}
                className={`w-full flex flex-row justify-between items-center border-2 border-[#000000] text-[16px] font-semibold px-10 py-4 gap-10 rounded-[10px] ${
                  isSelected ? "bg-[#E2D1F9]" : "bg-[#faecdf] opacity-40"
                }`}
                onClick={handleselectedpackage}
              >
                <div>{name[0]}</div>
                <div>₹ {packagePrice}</div>
              </div>
            </div>
          ) : (
            <div className="w-full flex flex-col justify-between my-4 gap-4 cursor-pointer">
              <div
                key={packageId}
                className={`w-full flex flex-row justify-between items-center border-2 border-[#000000] text-[16px] font-semibold px-10 py-4 gap-10 rounded-[10px] ${
                  isSelected ? "bg-[#E2D1F9]" : "bg-[#faecdf] opacity-40"
                }`}
                onClick={handleselectedpackage}
              >
                <div>{name[0]}</div>
                <div>₹ {packagePrice}</div>
              </div>
            </div>
          )}
        </>
      ) : null}
    </>
  );
}

export default PackageSelection;
