import React from "react";
import NavBar from "./components/navbar/NavBar";
import { Outlet } from "react-router-dom";
import GoToTop from "./components/gototop/GoToTop";
import Whatsapp from "./components/contact/Whatsapp";

function Layout() {
  return (
    <>
      <NavBar />
      <GoToTop />
      <Whatsapp />
      <Outlet />
    </>
  );
}

export default Layout;
