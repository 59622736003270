import React from "react";
import Button from "../../../components/button/Button";
import blogpageimage from "../../../assets/disposableassets/blogpageimage.png";

function IntroductionSection() {
  return (
    <div className="px-[8%] py-10 flex flex-row max-md:flex-col justify-between items-center gap-8">
      <div className="flex flex-col justify-between gap-8 flex-1">
        <h1 className="text-[#000000] text-[32px] max-md:text-[26px] max-sm:text-[20px] font-bold font-sans">
          Embrace the Sacred at Home
        </h1>
        <p className="text-[#000000] text-[20px] max-sm:text-[16px] font-normal text-justify font-sans">
          🙏 Welcome! At <span className="font-bold">RitiVidhi</span>, we are
          passionate about sharing the wisdom of ancient Vedic rituals and
          traditions. 🌸 Since our journey began, we've explored countless
          rituals, spiritual practices, and mindful living tips. Whether you’re
          looking to deepen your spiritual practice or simply learn more about
          Hindu customs, you’ll find everything from pooja guides 🕉️, festival
          insights 🎉, meditation techniques 🧘‍♂️, and more.
        </p>
        <Button
          label="Show all recent posts"
          style={{
            backgroundColor: "#FFF1D0",
            color: "black",
            borderColor: "#000000",
          }}
        />
      </div>
      <div className="w-full flex flex-row gap-4 justify-between items-center flex-1">
        <div className="flex flex-col gap-0">
          <img
            src={blogpageimage}
            alt="blogpageimage"
            className="rounded-t-[10px]"
          />
          <span className="w-full flex justify-center bg-[#FFD981] py-1 px-4 rounded-b-[10px]">
            The Power of Vedic Mantras
          </span>
        </div>
        <div className="flex flex-col gap-0">
          <img
            src={blogpageimage}
            alt="blogpageimage"
            className="rounded-t-[10px]"
          />
          <span className="w-full flex justify-center bg-[#FFD981] py-1 px-4 rounded-b-[10px]">
            The Power of Vedic Mantras
          </span>
        </div>
      </div>
    </div>
  );
}

export default IntroductionSection;
