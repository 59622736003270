import React from "react";

function GroupPackageCard({ packagedescription }) {
  return (
    <>
      {packagedescription && packagedescription.length > 0 ? (
        <ul style={{ listStyleType: "disc", paddingLeft: "1.5rem" }}>
          {packagedescription.map((packagedescriptionlist, index) => {
            return (
              <li key={index} className="mb-4">
                {packagedescriptionlist}
              </li>
            );
          })}
        </ul>
      ) : (
        <div className="w-full py-6 my-4 flex justify-center text-[20px] font-bold font-sans text-[#000000] bg-[#faecdf] rounded-[10px]">
          Select any package
        </div>
      )}
    </>
  );
}

export default GroupPackageCard;
