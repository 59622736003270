import React, { useContext, useState } from "react";
import Modal from "@mui/material/Modal";
import { ContextData } from "../../context/Context";
import cross from "../../assets/cross.svg";
import LazyLoad from "react-lazyload";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

function GroupPujaCartModal({ cartpujadata }) {
  const {
    opentemplepujacartModal,
    setOpenTemplePujacartModal,
    handletemplepujacartClose,
    setCurrentpujadetail,
    setSpecialRequirement,
    setselectedmode,
    setCarttemplePujaData,
    finaltemplecartpujadata,
    setFinalTempleCartPujaData,
    templepujatotal,
  } = useContext(ContextData);

  const [isExiting, setIsExiting] = useState(false);

  let finaltotal =
    parseFloat(templepujatotal) + parseFloat(cartpujadata[0].templePrice);

  const handlecart = () => {
    const updatedCartItem = { ...cartpujadata[0], finaltotal };
    setFinalTempleCartPujaData([...finaltemplecartpujadata, updatedCartItem]);
    setCarttemplePujaData([]);
    setselectedmode("");
    setSpecialRequirement("");
    setCurrentpujadetail([]);
    setOpenTemplePujacartModal(false);
  };

  const handleClose = () => {
    setIsExiting(true);
    setTimeout(() => {
      handletemplepujacartClose();
      setIsExiting(false);
    }, 500);
  };

  const modalVariants = {
    hidden: { opacity: 0, x: 300 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 300 },
  };

  return <div></div>;
}

export default GroupPujaCartModal;
