import React from "react";
import categorypagemainimage from "../../assets/disposableassets/categorypagemainimage.png";
import Button from "../button/Button";
import LazyLoad from "react-lazyload";

function GroupPujaMainCard() {
  return (
    <div
      className="h-min border border-2 border-[#CCA7FF] flex flex-row max-md:flex-col gap-4 rounded-[20px] cursor-pointer"
      style={{
        background:
          "linear-gradient(180deg, #FFE9B4 0%, rgba(251, 209, 110, 0.30) 50%)",
      }}
    >
      <div className="flex flex-col justify-center gap-8 px-8 py-2">
        <h1 className="text-[22px] font-sans font-bold text-[#000000]">
          Lorem ipsum dolor: sit amet consectetur Congue in nisl vivamus!!
        </h1>
        <Button
          label="Book Now"
          style={{
            backgroundColor: "#FBD16E",
            color: "black",
            borderColor: "#FF8213",
          }}
        />
      </div>
      <LazyLoad height={300} offset={100}>
        <img
          src={categorypagemainimage}
          alt="Visual representation of Group Puja"
          className="w-[550px] h-[300px] rounded-r-[16px] max-md:w-auto max-md:h-auto max-md:rounded-r-[0px] max-md:rounded-b-[16px]"
        />
      </LazyLoad>
    </div>
  );
}

export default GroupPujaMainCard;
