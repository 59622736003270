import React from "react";
import Button from "../button/Button";

function ServiceContact() {
  return (
    <div
      className="flex flex-col justify-between border border-2 border-[#B8F3DE] rounded-[20px] p-8 h-full"
      style={{
        background: "linear-gradient(180deg, #FFF 20.5%, #EAF5F1 43.5%)",
      }}
    >
      <h1 className="font-sans text-[20px] not-italic font-bold text-[#000000]">
        Not sure? Discover the perfect Puja tailored for you
      </h1>
      <p className="text-[16px] font-sans non-italic font-normal text-[#000000] mb-4">
        Lorem ipsum dolor sit amet consectetur. At in commodo aliquam venenatis.
        Viverra feugiat in a massa euismod hac.
      </p>
      <div className="flex flex-row max-lg:flex-col max-md:flex-row justify-between gap-4">
        <div className="flex flex-col gap-4 w-full">
          <input
            type="email"
            placeholder="example@username.com"
            className="w-full border border-[#938D8D] rounded-[10px] bg-[#FFF1D0] px-4 py-1 placeholder-[16px] placeholder-font-sans placeholder-non-italic placeholder-text-[#7B7575] placeholder-font-normal"
          />
          <input
            type="text"
            placeholder="Your short message"
            className="w-full border border-[#938D8D] rounded-[10px] bg-[#FFF1D0] px-4 py-1 placeholder-[16px] placeholder-font-sans placeholder-non-italic placeholder-text-[#7B7575] placeholder-font-normal"
          />
        </div>
        <div className="self-end max-lg:self-start max-md:self-end">
          <Button
            label="Contact"
            style={{
              backgroundColor: "#FFF1D0",
              color: "black",
              borderColor: "#000000",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ServiceContact;
