import React, { useContext } from "react";
import { ContextData } from "../../../../context/Context";
import AddonCard from "../../../../components/addson/AddonCard";

function TemplePujaAddons() {
  const { selectedcopytemplepujadetail, setSelectedCopyTemplePujadetail } =
    useContext(ContextData);

  return (
    <>
      {selectedcopytemplepujadetail &&
      selectedcopytemplepujadetail.length !== 0 ? (
        <>
          {selectedcopytemplepujadetail.map((product) => {
            const { pujaId, addOns } = product;

            return (
              <div key={pujaId} className="flex flex-row max-md:flex-col">
                <div className="flex-1 overflow-auto scrollbar-hide">
                  <div className="flex flex-row justify-between items-center my-6 text-[16px] max-md:text-[16px] max-sm:text-[12px] font-inter text-justify px-10">
                    <span className="text-[#000000] font-semibold">
                      Add Ons
                    </span>
                  </div>
                  <AddonCard
                    addOns={addOns}
                    currentpujadetail={selectedcopytemplepujadetail}
                    setCurrentpujadetail={setSelectedCopyTemplePujadetail}
                    cartpujadata={selectedcopytemplepujadetail}
                    setCartPujaData={setSelectedCopyTemplePujadetail}
                  />
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
}

export default TemplePujaAddons;
