import React from "react";
import happy from "../../../../../assets/grouppujaassets/happy.png";

function GroupPujaBenefits() {
  let pujaBenefits = [
    {
      id: "benefit1",
      heading: "Blessings for Abundance of Wealth and Bliss in Life",
      description:
        "It is believed that chanting the Mahalakshmi Mantra 11,000 times invokes divine blessings for financial abundance and happiness. Devotees receive the grace of Maa Mahalakshmi to fulfill their desires for wealth and overall prosperity.",
      imageURL: happy,
    },
    {
      id: "benefit2",
      heading: "Blessings for Abundance of Wealth and Bliss in Life",
      description:
        "It is believed that chanting the Mahalakshmi Mantra 11,000 times invokes divine blessings for financial abundance and happiness. Devotees receive the grace of Maa Mahalakshmi to fulfill their desires for wealth and overall prosperity.",
      imageURL: happy,
    },
    {
      id: "benefit3",
      heading: "Blessings for Abundance of Wealth and Bliss in Life",
      description:
        "It is believed that chanting the Mahalakshmi Mantra 11,000 times invokes divine blessings for financial abundance and happiness. Devotees receive the grace of Maa Mahalakshmi to fulfill their desires for wealth and overall prosperity.",
      imageURL: happy,
    },
    {
      id: "benefit4",
      heading: "Blessings for Abundance of Wealth and Bliss in Life",
      description:
        "It is believed that chanting the Mahalakshmi Mantra 11,000 times invokes divine blessings for financial abundance and happiness. Devotees receive the grace of Maa Mahalakshmi to fulfill their desires for wealth and overall prosperity.",
      imageURL: happy,
    },
  ];

  return (
    <div className="px-[8%] pt-24 max-md:pt-36 max-sm:pt-20 flex flex-col">
      <h1 className="text-[20px] max-md:text-[18px] font-bold font-inter text-[#00000] mb-10">
        Puja Benefits
      </h1>
      <div className="grid grid-cols-2 max-md:grid-cols-1 gap-16">
        {pujaBenefits.map((benefit) => {
          const { id, heading, description, imageURL } = benefit;
          return (
            <div key={id} className="flex flex-row items-start gap-4">
              <img src={imageURL} alt="happy" className="size-12" />
              <div className="flex flex-col gap-4">
                <h1 className="text-[18px] max-md:text-[16px] font-bold font-inter text-[#00000]">
                  {heading}
                </h1>

                <p className="text-[18px] max-md:text-[16px] text-justify font-normal font-inter text-[#00000]">
                  {description}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default GroupPujaBenefits;
