import React, { useContext, useEffect } from "react";
import AddonCard from "../../../components/addson/AddonCard";
import { ContextData } from "../../../context/Context";

function AddOns() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    currentpujadetail,
    setCurrentpujadetail,
    cartpujadata,
    setCartPujaData,
  } = useContext(ContextData);

  return (
    <>
      {currentpujadetail && currentpujadetail.length !== 0 ? (
        <>
          {currentpujadetail.map((product) => {
            const { pujaId, addOns } = product;

            return (
              <div key={pujaId} className="flex flex-row max-md:flex-col">
                <div className="flex-1 overflow-auto scrollbar-hide">
                  <div className="flex flex-row justify-between items-center my-6 text-[16px] max-md:text-[16px] max-sm:text-[12px] font-inter text-justify px-10">
                    <span className="text-[#000000] font-semibold">
                      Add Ons
                    </span>
                  </div>
                  <AddonCard
                    addOns={addOns}
                    currentpujadetail={currentpujadetail}
                    setCurrentpujadetail={setCurrentpujadetail}
                    cartpujadata={cartpujadata}
                    setCartPujaData={setCartPujaData}
                  />
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
}

export default AddOns;
