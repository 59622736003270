import React from "react";
import kumbhkalash from "../../../assets/landingpageicons/kumbhkalash.gif";
import shoppingcart from "../../../assets/landingpageicons/shoppingcart.gif";
import namaste from "../../../assets/landingpageicons/namaste.gif";

function BookPuja() {
  const bookingsteps = [
    {
      id: 1,
      heading: "Select Category, Pooja & Package",
      description:
        "Choose your mode – online, offline, or temple – and select the pooja and package.",
      image: kumbhkalash,
    },
    {
      id: 2,
      heading: "Add Add-ons, Choose Date & Provide Details",
      description:
        "Pick add-ons like Prasad or flowers, set the pooja date, and enter your address",
      image: shoppingcart,
    },
    {
      id: 3,
      heading: "Book, Pay & Experience Divinity",
      description:
        "Confirm your details, make the payment, and enjoy a sacred pooja experience",
      image: namaste,
    },
  ];

  return (
    <div className="px-[8%] py-10 flex flex-col gap-8">
      <div className="font-sans text-[#000000] text-[32px] not-italic font-bold">
        Explore, Choose, and Book Your Puja in Just a Few Steps
      </div>
      <div className="grid grid-cols-3 gap-4 max-lg:grid-cols-1 max-lg:gap-8">
        {bookingsteps.map((steps) => {
          const { id, heading, description, image } = steps;
          return (
            <div
              key={id}
              className="flex flex-row items-center max-xl:items-start gap-4"
            >
              <img
                src={image}
                alt={heading}
                className="size-20"
                loading="lazy"
              />
              <div className="flex flex-col">
                <h3 className="font-sans text-[#000000] text-[20px] not-italic font-semibold leading-6">
                  {heading}
                </h3>
                <p className="font-roboto font-normal leading-6 text-[#000000] not-italic text-[12px] ">
                  {description}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BookPuja;
