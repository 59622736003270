import React, { useContext, useState } from "react";
import arrowback from "../../assets/arrowback.svg";
import { ContextData } from "../../context/Context";
import { Modal } from "@mui/material";

function GroupPujaUserDetailModal() {
  const [mobileNumber, setMobileNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [errors, setErrors] = useState({ mobileNumber: "", fullName: "" });

  const {
    opendetailnmodal,
    setOpendetailnmodal,
    nextgroupstep,
    selectedgrouppackage,
    cartpujadata,
  } = useContext(ContextData);

  const handleClose = () => {
    setOpendetailnmodal(false);
    setErrors({ mobileNumber: "", fullName: "" });
    setMobileNumber("");
    setFullName("");
  };

  const validateForm = () => {
    const newErrors = { mobileNumber: "", fullName: "" };
    let isValid = true;

    if (!/^\d{10}$/.test(mobileNumber)) {
      newErrors.mobileNumber = "Please enter a valid 10-digit mobile number.";
      isValid = false;
    }
    if (fullName.trim() === "") {
      newErrors.fullName = "Full name cannot be empty.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handlegrouppujadetail = () => {
    if (validateForm()) {
      handleClose();
      nextgroupstep();
    }
  };

  return (
    <Modal
      open={opendetailnmodal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus
      className="bg-[#00000099]"
    >
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[400px] bg-white border-2 border-[#FF8213CC] shadow-xl px-8 py-6 rounded-[10px]">
        <div
          id="modal-modal-description"
          sx={{ mt: 2 }}
          className="flex flex-col gap-2"
        >
          <div className="w-full flex flex-row gap-4 items-center">
            <img
              src={arrowback}
              alt="arrowback"
              className="size-6 cursor-pointer"
              onClick={handleClose}
            />
            <span className="font-sans text-[16px] text-[#000000] font-semibold">
              Fill your details for Puja
            </span>
          </div>
          <hr />
          <h1 className="font-sans text-[20px] text-[#000000] font-bold">
            Mobile Number
          </h1>
          <span className="pt-2 text-BLACK-60 text-[12px] w-full font-normal text-left mb-2">
            Your Puja booking updates like Puja Photos, Videos and other details
            will be sent on WhatsApp on below number.
          </span>
          <input
            type="text"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            className="py-2 px-4 rounded-[10px] border border-[#FF8213] mb-1"
          />
          {errors.mobileNumber && (
            <span className="text-red-500 text-[12px]">
              {errors.mobileNumber}
            </span>
          )}
          <label className="font-sans text-[18px] text-[#000000] font-semibold mb-2">
            Full Name
          </label>
          <input
            type="text"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            className="py-2 px-4 rounded-[10px] border border-[#FF8213] mb-1"
          />
          {errors.fullName && (
            <span className="text-red-500 text-[12px]">{errors.fullName}</span>
          )}
          <button
            className="w-full py-3 flex justify-center rounded-[10px] text-[18px] text-[#ffffff] bg-[#FF8213]"
            onClick={handlegrouppujadetail}
          >
            Next
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default GroupPujaUserDetailModal;
