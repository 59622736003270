import React, { useEffect } from "react";
import Footer from "../../../../components/footer/Footer";
import TempleList from "./sections/TempleList";

function TempleCategory() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <TempleList />
      <Footer />
    </>
  );
}

export default TempleCategory;
