import React from "react";
import minusaddon from "../../assets/minusaddon.svg";
import plusaddon from "../../assets/plusaddon.svg";

function AddonCard({
  addOns,
  currentpujadetail,
  setCurrentpujadetail,
  cartpujadata,
  setCartPujaData,
}) {
  const updateAddonsquantity = (id, value) => {
    if (value < 0) return;

    const updatedPujaDetail = currentpujadetail.map((pujaDetail) => {
      const updatedAddons = pujaDetail.addOns.map((add) => {
        if (add.addonId === id) {
          const newPrice = add.price * value;
          return { ...add, quantity: value, newPrice };
        }
        return add;
      });
      return { ...pujaDetail, addOns: updatedAddons };
    });
    setCurrentpujadetail(updatedPujaDetail);

    const updatedcartPujaDetail = cartpujadata.map((pujaDetail) => {
      const updatedAddons = pujaDetail.addOns.map((add) => {
        if (add.addonId === id) {
          const newPrice = add.price * value;
          return { ...add, quantity: value, newPrice };
        }
        return add;
      });
      return { ...pujaDetail, addOns: updatedAddons };
    });
    setCartPujaData(updatedcartPujaDetail);
  };

  return (
    <div className="flex flex-col justify-between gap-4 mb-10 w-full px-10">
      {addOns.map((add) => {
        const { addonId, name, description, price, quantity } = add;
        return (
          <div
            key={addonId}
            className="flex flex-row justify-between max-sm:flex-col max-md:gap-4 w-full"
          >
            <div className="flex flex-col justify-between border-2 border-[#795EE3] px-4 py-3 rounded-[10px] gap-4 bg-[#E2D1F9] text-[#000000] text-[16px] max-md:text-[12px] font-inter font-bold w-full">
              <div className="flex flex-row justify-between">
                <span>{name}</span>
                <span>₹ {price}</span>{" "}
              </div>
              <div className="font-medium">{description}</div>
            </div>
            <div className="flex flex-row justify-end max-md:justify-around gap-10 items-center w-full">
              <div className="flex flex-row items-center gap-2">
                <img
                  src={minusaddon}
                  alt="minusaddon"
                  className="size-8 max-md:size-6"
                  onClick={() => updateAddonsquantity(addonId, quantity - 1)}
                  style={{ cursor: quantity > 0 ? "pointer" : "not-allowed" }}
                />
                <img
                  src={plusaddon}
                  alt="plusaddon"
                  className="size-8 max-md:size-6"
                  onClick={() => updateAddonsquantity(addonId, quantity + 1)}
                />
              </div>
              <span className="text-[22px] max-md:text-[16px] font-medium font-inter text-[#000000] text-justify">
                {quantity}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default AddonCard;
