import React from "react";
import peacehand from "../../../../../assets/grouppujaassets/male-with-peace-hand.png";

function Reviews({ reviews }) {
  return (
    <div className="mx-[8%] p-8 mt-24 max-md:mt-36 max-sm:mt-20 bg-[#ffffff] rounded-[20px]">
      <h1 className="text-[20px] max-md:text-[18px] font-bold font-inter text-[#00000] mb-5">
        What devotees say about RitiVidhi?
      </h1>
      <p className="mb-6">
        Reviews and ratings from our customers who performed online Puja with
        us.
      </p>
      <div className="grid grid-cols-4 max-md:grid-cols-2 max-sm:grid-cols-1 gap-8">
        {reviews && reviews.length > 0 ? (
          reviews.map((review) => {
            const { id, userreview, name } = review;
            return (
              <div key={id} className="flex flex-col gap-4 mb-4">
                <p className="text-[14px] max-md:text-[12px] text-justify font-normal font-inter text-[#00000] bg-[#f1f1f1] p-4 rounded-[20px]">
                  {userreview}
                </p>
                <div className="flex flex-row items-center gap-4">
                  <img src={peacehand} alt="peacehand" className="size-12" />
                  <span className="font-semibold">{name}</span>
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-gray-500">No reviews available.</p>
        )}
      </div>
    </div>
  );
}

export default Reviews;
