import React, { useContext } from "react";
import { ContextData } from "../../../../../context/Context";
import categorypagemainimage from "../../../../../assets/disposableassets/categorypagemainimage.png";
import CategoryPageCard from "../../../../../components/cards/CategoryPageCard";
import LazyLoad from "react-lazyload";

function TempleList() {
  const {
    templesummary,
    setCurrentTemplePuja,
    setCurrenttempledetail,
    setCarttemplePujaData,
  } = useContext(ContextData);

  const handletemplepuja = async (templeId) => {
    setCurrentTemplePuja(1);
    try {
      const response = await fetch(
        "https://app.ritividhi.com/ritividhi/temple-puja"
      );
      const currenttemplepuja = await response.json();
      const currenttemplepujadata = currenttemplepuja.filter(
        (temple) => temple.templeId === templeId
      );
      setCarttemplePujaData(currenttemplepujadata);
      setCurrenttempledetail(currenttemplepujadata);
    } catch (error) {
      console.error("Error fetching all pujas summary", error);
    }
  };

  return (
    <>
      <div className="px-[8%] py-10">
        <div className="flex flex-col gap-4 max-lg:hidden">
          <div
            className="h-min border border-2 border-[#CCA7FF] flex flex-row justify-between pr-10 gap-4 rounded-[20px] cursor-pointer"
            style={{
              background: "linear-gradient(90deg, #E2D1F9 55.5%, #FFF 100%)",
            }}
          >
            <LazyLoad height={300} offset={100}>
              <img
                src={categorypagemainimage}
                alt="Visual representation of Group Puja"
                className="w-[550px] h-[300px] rounded-l-[16px] max-md:w-auto max-md:h-auto max-md:rounded-l-[0px] max-md:rounded-b-[16px]"
              />
            </LazyLoad>
            <div className="flex flex-col justify-center gap-2 px-4 py-2">
              <h1 className="text-[22px] font-sans non-italic font-bold text-[#000000]">
                Hello
              </h1>
              <p className="text-[16px] font-sans non-italic font-normal text-[#000000] mb-4 block max-sm:hidden">
                Hey
              </p>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4">
            {templesummary.map((temple) => {
              const {
                templeId,
                templeName,
                templeLocation,
                templeImage,
                description,
                buttonlabel = "Book Now",
                time,
              } = temple;
              return (
                <div key={templeId}>
                  <CategoryPageCard
                    name={templeName}
                    pujaImageURL={templeImage}
                    description={description}
                    buttonlabel={buttonlabel}
                    time={time}
                    templeLocation={templeLocation}
                    onClick={() => handletemplepuja(templeId)}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className="hidden max-lg:flex max-lg:flex-col max-lg:gap-8">
          <div className="grid grid-cols-2 gap-4">
            {templesummary.map((temple) => {
              const {
                templeId,
                templeName,
                templeLocation,
                templeImage,
                description,
                buttonlabel = "Book Now",
                time,
              } = temple;
              return (
                <div key={templeId}>
                  <CategoryPageCard
                    name={templeName}
                    pujaImageURL={templeImage}
                    description={description}
                    buttonlabel={buttonlabel}
                    time={time}
                    templeLocation={templeLocation}
                    onClick={() => handletemplepuja(templeId)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default TempleList;
