import React, { useContext } from "react";
import LazyLoad from "react-lazyload";
import Button from "../button/Button";
import { Link } from "react-router-dom";
import { ContextData } from "../../context/Context";
import grouppuja from "../../assets/landingpageicons/grouppuja.gif";

function Service3() {
  const { setCurrentGroupPuja } = useContext(ContextData);
  return (
    <div
      className="border border-2 border-[#FF8ED1] flex flex-col gap-4 rounded-[20px] max-xl:flex-row max-xl:items-center"
      style={{
        background: "linear-gradient(180deg, #FFEBF7 63%, #FFF 100%)",
      }}
    >
      <LazyLoad height={300}>
        <div className="flex items-center justify-center h-[300px]">
          <img
            src={grouppuja}
            alt="grouppuja"
            className="w-[500px] h-[300px] self-center"
          />
        </div>
      </LazyLoad>
      <div className="flex flex-col p-4">
        <h1 className="text-[28px] font-sans non-italic font-bold text-[#000000]">
          Upcoming Group Pujas on Ritividhi
        </h1>
        <p className="text-[16px] font-sans non-italic font-normal text-[#000000] mb-4">
          Be a part of powerful group pujas. Book in your name and gotra,
          receive the puja video, tirth prasad, and divine blessings.
        </p>
        <Link to="/group">
          <Button
            label="Explore"
            style={{
              backgroundColor: "#FFF1D0",
              color: "black",
              borderColor: "#000000",
            }}
            onClick={() => setCurrentGroupPuja(0)}
          />
        </Link>
      </div>
    </div>
  );
}

export default Service3;
