import React from "react";

function Navigation() {
  return (
    <div className="flex flex-col gap-4 font-sans text-[#00000] text-[20px] max-lg:text-[16px] font-normal non-italic">
      <h1 className="text-[22px] max-lg:text-[18px] font-bold">Navigations</h1>
      <span>Home</span>
      <span>About us</span>
      <span>Pujas</span>
      <span>Contact us</span>
    </div>
  );
}

export default Navigation;
