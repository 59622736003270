import React, { createContext, useEffect, useState } from "react";
import officeservice from "../assets/landingpageicons/officeservice.gif";
import ProductInfo from "../pages/productpage/sections/productinfo/ProductInfo";
import AddressForm from "../pages/productpage/sections/AddressForm";
import DateTime from "../pages/productpage/sections/DateTime";
import PujaCategory from "../pages/productpage/sections/category/PujaCategory";
import pujaservices from "../assets/landingpageicons/pujaservices.gif";
import templeservice from "../assets/landingpageicons/templeservice.gif";
import astrology from "../assets/landingpageicons/astrology.gif";
import meditation from "../assets/landingpageicons/meditation.gif";
import grouppujaimage from "../assets/landingpageicons/grouppuja.gif";
import GroupPujaInfo from "../pages/grouppuja/sections/GroupPujaInfo";
import GroupPujaProductpage from "../pages/grouppuja/sections/grouppujaproductpage/GroupPujaProductpage";
import GroupPujaAddress from "../pages/grouppuja/sections/grouppujaaddress/GroupPujaAddress";
import GroupPujaDateTime from "../pages/grouppuja/sections/grouppujadatetime/GroupPujaDateTime";
import blogpageimage from "../assets/disposableassets/blogpageimage.png";
import TempleCategory from "../pages/templepuja/sections/category/TempleCategory";
import TemplePujas from "../pages/templepuja/sections/templepuja/TemplePujas";
import CurrentTemplePuja from "../pages/templepuja/sections/currenttemplepuja/CurrentTemplePuja";
import TemplePujaAddressForm from "../pages/templepuja/sections/addressdetails/TemplePujaAddressForm";
import DateTimeReview from "../pages/templepuja/sections/datetimeReview/DateTimeReview";

export const ContextData = createContext();

function Context({ children }) {
  const services = [
    {
      id: 1,
      heading: "Puja Services",
      description:
        "Effortlessly book personalized poojas for your home or events, performed by expert pandits with authentic rituals and devotion.",
      imageURL: pujaservices,
      buttonlabel: "Book a Pooja",
      background: "linear-gradient(90deg, #E2D1F9 55.5%, #FFF 100%)",
      redirect: "/product",
    },
    {
      id: 2,
      heading: "Temple Services",
      description:
        "Connect with the divine from anywhere. Book poojas at your chosen temple and receive blessings, along with sacred prasad",
      imageURL: templeservice,
      buttonlabel: "Explore",
      background: "linear-gradient(180deg, #FFF 20.5%, #EAF5F1 43.5%)",
      redirect: "/temple",
    },
    {
      id: 3,
      heading: "Upcoming Group Pujas on Ritividhi",
      description:
        "Be a part of powerful group pujas. Book in your name and gotra, receive the puja video, tirth prasad, and divine blessings.",
      imageURL: grouppujaimage,
      buttonlabel: "Explore",
      background: "linear-gradient(180deg, #FFEBF7 63%, #FFF 100%)",
      redirect: "/group",
    },
    {
      id: 4,
      heading: "Astrology and Consultancy (Coming Soon)",
      description:
        "Unlock the wisdom of the stars with personalized astrology and expert guidance, helping you navigate life’s important decisions.",
      imageURL: astrology,
      buttonlabel: "Explore",
      background: "linear-gradient(180deg, #FFF 0%, #FBEEB8 51.5%)",
      redirect: "/",
    },
    {
      id: 5,
      heading: "Corporate Services (Coming Soon)",
      description:
        "Invite prosperity and success to your business with corporate poojas, including Vastu Shanti, Ganesh Poojan, and other rituals tailored to enhance positive energy in your workplace.",
      imageURL: officeservice,
      buttonlabel: "Book a Pooja",
      background: "linear-gradient(90deg, #F3E9D2 57.5%, #FFF 100%)",
      redirect: "/",
    },
    {
      id: 6,
      heading: "Meditation (Coming Soon)",
      description: "Coming Soon...",
      imageURL: meditation,
      buttonlabel: "Book a Pooja",
      background: "linear-gradient(90deg, #F3E9D2 57.5%, #FFF 100%)",
      redirect: "/",
    },
  ];

  const allpujasteps = [
    {
      title: "Choose a Puja",
      content: <PujaCategory />,
    },
    {
      title: "Select Package",
      content: <ProductInfo />,
    },
    {
      title: "Delivery Address",
      content: <AddressForm />,
    },
    {
      title: "Date, Time & Review",
      content: <DateTime />,
    },
  ];

  const grouppujasteps = [
    {
      title: "Choose a Group Puja",
      content: <GroupPujaInfo />,
    },
    {
      title: "Select Package",
      content: <GroupPujaProductpage />,
    },
    {
      title: "Delivery Address",
      content: <GroupPujaAddress />,
    },
    {
      title: "Date, Time & Review",
      content: <GroupPujaDateTime />,
    },
  ];

  const templepujasteps = [
    {
      title: "Choose a Temple",
      content: <TempleCategory />,
    },
    {
      title: "Choose a Puja",
      content: <TemplePujas />,
    },
    {
      title: "Select Package",
      content: <CurrentTemplePuja />,
    },
    {
      title: "Delivery Address",
      content: <TemplePujaAddressForm />,
    },
    {
      title: "Date, Time & Review",
      content: <DateTimeReview />,
    },
  ];

  const orderdata = [
    {
      id: 1,
      orderid: "Order # 402-9961068-1509931",
      orderDate: "21 October 2024",
      orderAmount: "Rs 2000.00",
      orderPujaName: "Durga Saptashati Path",
      orderAddons: "Brahman Bhoj, Pandit Dakshina, Gau Seva",
      orderAddress:
        "Y - 34 B, Police Chawoki, Near Gita Niketan Sohbatiyabag, Prayagraj, 211019",
      orderPujaTime: "25 October 2024 at 05:00 PM",
      pujaMode: "Offline",
      package: "Premium",
      specialRequirement: "NA",
      orderDuration: "3 hours",
      number: "+91 8977452378",
    },
    {
      id: 2,
      orderid: "Order # 402-9961068-1509932",
      orderDate: "21 October 2024",
      orderAmount: "Rs 2000.00",
      orderPujaName: "Durga Saptashati Path",
      orderAddons: "Brahman Bhoj, Pandit Dakshina, Gau Seva",
      orderAddress:
        "Y - 34 B, Police Chawoki, Near Gita Niketan Sohbatiyabag, Prayagraj, 211019",
      orderPujaTime: "25 October 2024 at 05:00 PM",
      pujaMode: "Offline",
      package: "Premium",
      specialRequirement: "NA",
      orderDuration: "3 hours",
      number: "+91 8977452378",
    },
  ];

  const bloglist = [
    {
      imageurl: blogpageimage,
      text: "The Power of Vedic Mantras",
    },
    {
      imageurl: blogpageimage,
      text: "The Power of Vedic Mantras",
    },
    {
      imageurl: blogpageimage,
      text: "The Power of Vedic Mantras",
    },
    {
      imageurl: blogpageimage,
      text: "The Power of Vedic Mantras",
    },
    {
      imageurl: blogpageimage,
      text: "The Power of Vedic Mantras",
    },
    {
      imageurl: blogpageimage,
      text: "The Power of Vedic Mantras",
    },
    {
      imageurl: blogpageimage,
      text: "The Power of Vedic Mantras",
    },
    {
      imageurl: blogpageimage,
      text: "The Power of Vedic Mantras",
    },
  ];

  //Offline Puja
  const [pujasummary, setPujaSummary] = useState([]);
  const [currentpujadetail, setCurrentpujadetail] = useState([]);
  const [currentpuja, setCurrentPuja] = useState(0);
  const [pujasteps, setPujasteps] = useState(allpujasteps);
  const [selectedmode, setselectedmode] = useState("");
  const [selectedpackage, setselectedpackage] = useState("");
  const [cartpujadata, setCartPujaData] = useState([]);
  const [finalcartpujadata, setFinalCartPujaData] = useState([]);
  const [pujatotal, setPujaTotal] = useState(0);
  const [opencartModal, setOpencartModal] = useState(false);

  //Online Puja
  const [grouppujasummary, setGroupPujaSummary] = useState([]);
  const [currentgrouppujadetail, setCurrentgrouppujadetail] = useState([]);
  const [currentgrouptempledetail, setCurrentgrouptempledetail] = useState([]);
  const [currentgrouppuja, setCurrentGroupPuja] = useState(0);
  const [grouppujastep, setGroupPujaStep] = useState(grouppujasteps);
  const [selectedtemplemode, setselectedtemplemode] = useState("");
  const [cartgrouppujadata, setCartGroupPujaData] = useState([]);

  //Temple Puja
  const [templesummary, setTempleSummary] = useState([]);
  const [currenttempledetail, setCurrenttempledetail] = useState([]);
  const [selectedtemplepujadetail, setSelectedTemplePujadetail] = useState([]);
  const [currenttemplepuja, setCurrentTemplePuja] = useState(0);
  const [templepujastep, setTemplePujaStep] = useState(templepujasteps);
  const [selectedcopytemplepujadetail, setSelectedCopyTemplePujadetail] =
    useState([]);
  const [carttemplepujadata, setCarttemplePujaData] = useState([]);
  const [finaltemplecartpujadata, setFinalTempleCartPujaData] = useState([]);
  const [templepujatotal, setTemplePujaTotal] = useState(0);
  const [opentemplepujacartModal, setOpenTemplePujacartModal] = useState(false);

  //Service Cards
  const [cards, setcards] = useState(services);

  //Modals

  const [openNavModal, setOpenNavModal] = useState(false);
  const [openloginmodal, setOpenloginmodal] = useState(false);
  const [opendetailnmodal, setOpendetailnmodal] = useState(false);

  //Package
  const [selectedgrouppackage, setSelectedgrouppackage] = useState([]);

  //Account
  const [loggedIn, setLoggedIn] = useState(false);
  const [login, setLogin] = useState(true);

  //Order
  const [orderlist, setOrderlist] = useState(orderdata);
  const [orderdetail, setorderdetail] = useState([]);

  //Blogs
  const [blogdata, setblogdata] = useState(bloglist);
  const [posts, setPosts] = useState([]);

  //Date/Time
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [specialRequirement, setSpecialRequirement] = useState("");

  useEffect(() => {
    const allpuja = async () => {
      try {
        const response = await fetch(
          "https://app.ritividhi.com/ritividhi/pujaSummary"
        );
        const pujadata = await response.json();
        setPujaSummary(pujadata);
      } catch (error) {
        console.error("Error fetching all pujas summary", error);
      }
    };
    allpuja();
  }, []);

  useEffect(() => {
    const allgrouppuja = async () => {
      try {
        const response = await fetch(
          "https://app.ritividhi.com/ritividhi/online-puja"
        );
        const pujadata = await response.json();
        setGroupPujaSummary(pujadata);
      } catch (error) {
        console.error("Error fetching all groupn pujas summary", error);
      }
    };
    allgrouppuja();
  }, []);

  useEffect(() => {
    const templeservice = async () => {
      try {
        const response = await fetch(
          "https://app.ritividhi.com/ritividhi/templeSummary"
        );
        const templedata = await response.json();
        setTempleSummary(templedata);
      } catch (error) {
        console.error("Error fetching all temple summary", error);
      }
    };
    templeservice();
  }, []);

  const siteUrl = "https://ritividhi.wpcomstaging.com";
  const username = process.env.REACT_APP_USERNAME;
  const password = process.env.REACT_APP_PASSWORD;

  useEffect(() => {
    async function fetchAllPostsWithAuth() {
      const fetchedPosts = [];
      let page = 1;
      const perPage = 100;
      const maxPages = 10;

      const auth = "Basic " + btoa(username + ":" + password);

      while (page <= maxPages) {
        const endpoint = `${siteUrl}/wp-json/wp/v2/posts`;
        const params = new URLSearchParams({
          per_page: perPage,
          page: page,
        });

        try {
          const response = await fetch(`${endpoint}?${params}`, {
            method: "GET",
            headers: {
              Authorization: auth,
            },
          });

          if (response.ok) {
            const data = await response.json();
            fetchedPosts.push(...data);

            if (data.length < perPage) {
              break;
            }
          } else {
            console.error(
              `Error fetching page ${page}: ${response.status} - ${response.statusText}`
            );
            break;
          }

          page++;
        } catch (error) {
          console.error(`Fetch error: ${error}`);
          break;
        }
      }
      setPosts(fetchedPosts);
    }

    fetchAllPostsWithAuth();
  }, [siteUrl, username, password]);

  const nextstep = () => {
    setCurrentPuja(currentpuja + 1);
  };

  const prevstep = () => {
    setCurrentPuja(currentpuja - 1);
  };

  const nextgroupstep = () => {
    setCurrentGroupPuja(currentgrouppuja + 1);
  };

  const prevgroupstep = () => {
    setCurrentGroupPuja(currentgrouppuja - 1);
  };

  const nexttemplestep = () => {
    setCurrentTemplePuja(currenttemplepuja + 1);
  };

  const prevtemplestep = () => {
    setCurrentTemplePuja(currenttemplepuja - 1);
  };

  const handlecartOpen = () => {
    setOpencartModal(true);
  };

  const handlecartClose = () => {
    setOpencartModal(false);
  };

  const handletemplepujacartOpen = () => {
    setOpenTemplePujacartModal(true);
  };

  const handletemplepujacartClose = () => {
    setOpenTemplePujacartModal(false);
  };

  const handlenavOpen = () => {
    setOpenNavModal(true);
  };

  const handleNavClose = () => {
    setOpenNavModal(false);
  };

  return (
    <ContextData.Provider
      value={{
        pujasummary,
        currentpujadetail,
        setCurrentpujadetail,
        grouppujasummary,
        currentgrouppujadetail,
        setCurrentgrouppujadetail,
        templesummary,
        currenttempledetail,
        setCurrenttempledetail,
        selectedtemplepujadetail,
        setSelectedTemplePujadetail,
        selectedDate,
        setSelectedDate,
        selectedTime,
        setSelectedTime,
        specialRequirement,
        setSpecialRequirement,
        currentgrouptempledetail,
        setCurrentgrouptempledetail,
        selectedcopytemplepujadetail,
        setSelectedCopyTemplePujadetail,

        cartpujadata,
        setCartPujaData,
        finalcartpujadata,
        setFinalCartPujaData,
        pujatotal,
        setPujaTotal,
        carttemplepujadata,
        setCarttemplePujaData,
        opentemplepujacartModal,
        setOpenTemplePujacartModal,
        handletemplepujacartOpen,
        handletemplepujacartClose,
        finaltemplecartpujadata,
        setFinalTempleCartPujaData,
        templepujatotal,
        setTemplePujaTotal,
        cartgrouppujadata,
        setCartGroupPujaData,

        cards,
        setcards,
        currentpuja,
        setCurrentPuja,
        currentgrouppuja,
        setCurrentGroupPuja,
        currenttemplepuja,
        setCurrentTemplePuja,
        nextstep,
        prevstep,
        nextgroupstep,
        prevgroupstep,
        nexttemplestep,
        prevtemplestep,
        pujasteps,
        setPujasteps,
        grouppujastep,
        setGroupPujaStep,
        templepujastep,
        setTemplePujaStep,
        opencartModal,
        setOpencartModal,
        openNavModal,
        setOpenNavModal,
        handlecartOpen,
        handlecartClose,
        handlenavOpen,
        handleNavClose,
        openloginmodal,
        setOpenloginmodal,
        selectedgrouppackage,
        setSelectedgrouppackage,
        selectedmode,
        setselectedmode,
        selectedpackage,
        setselectedpackage,
        selectedtemplemode,
        setselectedtemplemode,
        loggedIn,
        setLoggedIn,
        login,
        setLogin,
        opendetailnmodal,
        setOpendetailnmodal,
        orderlist,
        setOrderlist,
        orderdetail,
        setorderdetail,
        blogdata,
        setblogdata,
        posts,
      }}
    >
      {children}
    </ContextData.Provider>
  );
}

export default Context;
