import React from "react";
import LazyLoad from "react-lazyload";
import Button from "../button/Button";
import pujaservices from "../../assets/landingpageicons/pujaservices.gif";
import { Link } from "react-router-dom";

function Service1() {
  return (
    <div
      className="border border-2 border-[#CCA7FF] flex flex-row justify-around items-center gap-4 rounded-[20px] max-xl:flex-col h-full pl-4 pr-8"
      style={{ background: "linear-gradient(90deg, #E2D1F9 55.5%, #FFF 100%)" }}
    >
      <LazyLoad height={300}>
        <img
          src={pujaservices}
          alt="pujaservices"
          className="w-[500px] h-[250px] max-xl:w-auto max-xl:scale-125"
        />
      </LazyLoad>
      <div className="flex flex-col justify-center gap-2">
        <h1 className="text-[28px] font-sans non-italic font-bold text-[#000000]">
          Puja Services
        </h1>
        <p className="text-[16px] font-sans non-italic font-normal text-[#000000] mb-4">
          Effortlessly book personalized poojas for your home or events,
          performed by expert pandits with authentic rituals and devotion
        </p>
        <div className="w-full flex justify-start">
          <Link to="/product">
            <Button
              label="Book a Pooja"
              style={{
                backgroundColor: "#FFF1D0",
                color: "black",
                borderColor: "#000000",
              }}
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Service1;
