import React, { useContext } from "react";
import { ContextData } from "../../context/Context";
import templeimage from "../../assets/temple.svg";
import grouptime from "../../assets/grouptime.svg";
import LazyLoad from "react-lazyload";

function GroupPujaCard({ pujaId, name, about, imageurl, liveEvents }) {
  const {
    grouppujastep,
    currentgrouppuja,
    nextgroupstep,
    setCurrentgrouppujadetail,
    setCartGroupPujaData,
  } = useContext(ContextData);

  const handlegrouppuja = async () => {
    try {
      const response = await fetch(
        "https://app.ritividhi.com/ritividhi/online-puja"
      );
      const currentgrouppuja = await response.json();

      const currentgrouppujadata = currentgrouppuja.filter(
        (group) => group.pujaId === pujaId
      )[0];

      if (!currentgrouppujadata) {
        console.error("No matching group puja found for the provided pujaId.");
        return;
      }

      const availableTemples =
        currentgrouppujadata?.liveEvents?.availableTemples;
      if (availableTemples?.[0]?.addons) {
        const updatedaddons = availableTemples[0].addons.map((addon) => ({
          ...addon,
          quantity: 0,
        }));
        availableTemples[0].addons = updatedaddons;
      }

      setCartGroupPujaData([currentgrouppujadata]);
      setCurrentgrouppujadetail([currentgrouppujadata]);
      nextgroupstep();
    } catch (error) {
      console.error("Error fetching all pujas summary", error);
    }
  };

  const pujaImage = imageurl[0].toLowerCase();
  const pujaimagearray = pujaImage.split("/");
  const groupimageurl = `https://${pujaimagearray[2]}.s3.amazonaws.com/${pujaimagearray[3]}/${pujaimagearray[4]}/${pujaimagearray[5]}`;

  return (
    <>
      <div
        className="border border-2 border-[#B8F3DE] flex flex-col gap-4 max-sm:gap-0 rounded-t-[10px] pb-4 cursor-pointer w-full"
        style={{
          background: "linear-gradient(180deg, #FFF 20.5%, #EAF5F1 43.5%)",
        }}
        onClick={() => handlegrouppuja()}
      >
        <LazyLoad height={200} offset={100}>
          <img src={groupimageurl} alt={name} className="rounded-t-[10px]" />
        </LazyLoad>
        <div className="px-8 py-2 flex flex-col justify-center gap-2">
          <h1 className="text-[20px] max-md:text-[18px] max-sm:text-[16px] font-sans non-italic font-bold text-[#000000]">
            {name}
          </h1>
          <p className="font-sans font-semibold text-[16px] max-md:text-[14px] max-sm:text-[12px] font-normal">
            {about}
          </p>
          <div className="flex flex-row gap-4 items-center">
            <LazyLoad height={20} offset={100}>
              <img src={templeimage} alt="Temple icon" />
            </LazyLoad>
            <span className="font-sans text-[14px] max-md:text-[14px] max-sm:text-[12px] font-normal">
              {liveEvents.availableTemples[0].templeName}{" "}
              {liveEvents.availableTemples[0].templeLocation}
            </span>
          </div>
          <div className="flex flex-row gap-4 items-center">
            <LazyLoad height={20} offset={100}>
              <img src={grouptime} alt="Time icon" />
            </LazyLoad>
            <span className="font-sans text-[14px] max-md:text-[14px] max-sm:text-[12px] font-normal">
              {}
            </span>
          </div>
        </div>
      </div>
      <div className="rounded-b-[10px]">
        {currentgrouppuja < grouppujastep.length - 1 && (
          <button
            className="w-full py-6 flex justify-center text-[18px] font-bold font-sans text-[#000000] rounded-b-[10px] border border-1 border-[#000000] bg-gradient-to-r from-[#d7d9f9] via-[#f9e6d7] to-[#d7d9f9] bg-[length:400%_400%] animate-gradient"
            onClick={() => handlegrouppuja()}
          >
            Participate Now
          </button>
        )}
      </div>
    </>
  );
}

export default GroupPujaCard;
