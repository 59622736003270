import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Layout from "./Layout";
import LandingPage from "./pages/landingPage/LandingPage";
import BlogPage from "./pages/blogPage/BlogPage";
import BlogView from "./pages/blogPage/sections/BlogView";
import Context from "./context/Context";
import ProductPage from "./pages/productpage/ProductPage";
import UserProfile from "./pages/userprofile/UserProfile";
import GroupPujaPage from "./pages/grouppuja/GroupPujaPage";
import OrderHistory from "./pages/userprofile/sections/orderhistory/OrderHistory";
import OrderDetail from "./pages/userprofile/sections/orderdetail/OrderDetail";
import TemplePujaPage from "./pages/templepuja/TemplePujaPage";
import AstrologyPage from "./pages/astrology/AstrologyPage";
import CartPage from "./pages/cartpage/CartPage";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<LandingPage />} />
      <Route path="blog" element={<BlogPage />} />
      <Route path="blogview" element={<BlogView />} />
      <Route path="product" element={<ProductPage />} />
      <Route path="user" element={<UserProfile />} />
      <Route path="group" element={<GroupPujaPage />} />
      <Route path="temple" element={<TemplePujaPage />} />
      <Route path="order" element={<OrderHistory />} />
      <Route path="orderdetail" element={<OrderDetail />} />
      <Route path="astrology" element={<AstrologyPage />} />
      <Route path="cart" element={<CartPage />} />
    </Route>
  )
);

function App() {
  return (
    <Context>
      <RouterProvider router={router}></RouterProvider>
    </Context>
  );
}

export default App;
