import React, { useContext, useEffect, useState } from "react";
import { ContextData } from "../../../../context/Context";
import FooterSmall from "../../../../components/footer/FooterSmall";
import Button from "../../../../components/button/Button";
import CartModal from "../../../../components/modals/CartModal";
import { DatePicker, TimePicker, Space, message } from "antd";
import clock from "../../../../assets/clock.svg";
import moment from "moment";
import Footer from "../../../../components/footer/Footer";
import GroupPujaAddon from "../grouppujaaddon/GroupPujaAddon";
import GroupPujaCartModal from "../../../../components/modals/GroupPujaCartModal";

function GroupPujaDateTime() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    grouppujastep,
    currentgrouppuja,
    nextgroupstep,
    prevgroupstep,
    setOpencartModal,
    currentgrouppujadetail,
    cartgrouppujadata,
    setCartGroupPujaData,
    cartdata,
    setCartData,
  } = useContext(ContextData);

  const [groupSelectedDate, setGroupSelectedDate] = useState(null);
  const [groupSelectedTime, setGroupSelectedTime] = useState(null);
  const [specialRequirement, setSpecialRequirement] = useState("");

  const handleNext = () => {
    if (!groupSelectedDate) {
      message.error("Please select a date.");
      return;
    }
    if (!groupSelectedTime) {
      message.error("Please select a time.");
      return;
    }

    const updatedpujadateTime = cartgrouppujadata.map((cartpujadatetime) => {
      if (cartgrouppujadata.length > 0) {
        return {
          ...cartpujadatetime,
          date: groupSelectedDate,
          time: groupSelectedTime,
          specialRequirement: specialRequirement,
        };
      }
    });

    nextgroupstep();
  };

  const handleDone = () => {
    if (!groupSelectedDate) {
      message.error("Please select a date.");
      return;
    }
    if (!groupSelectedTime) {
      message.error("Please select a time.");
      return;
    }

    const updatedpujadateTime = cartgrouppujadata.map((cartpujadatetime) => {
      if (cartgrouppujadata.length > 0) {
        return {
          ...cartpujadatetime,
          date: groupSelectedDate,
          time: groupSelectedTime,
          specialRequirement: specialRequirement,
        };
      }
    });

    setOpencartModal(true);
  };

  return (
    <>
      <div className="w-full">
        {currentgrouppujadetail.map((grouppuja) => {
          const { pujaId, name, imageurl } = grouppuja;

          const pujaImage = imageurl[0].toLowerCase();
          const pujaimagearray = pujaImage.split("/");
          const groupimageurl = `https://${pujaimagearray[2]}.s3.amazonaws.com/${pujaimagearray[3]}/${pujaimagearray[4]}/${pujaimagearray[5]}`;
          return (
            <div
              key={pujaId}
              className="flex flex-row max-md:gap-0 max-md:flex-col"
            >
              <div className="flex-1">
                <img
                  src={groupimageurl}
                  alt={name}
                  className="w-full h-screen border-t-4 border-r-4 border-[#795EE3] max-md:border-none max-sm:h-full"
                />
              </div>
              <div className="flex-1 h-screen overflow-auto scrollbar-hide">
                <div className="mt-5 px-10">
                  <Button
                    label="Previous"
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      borderColor: "#FF8213",
                    }}
                    onClick={() => prevgroupstep()}
                  />
                </div>
                <div className="flex flex-row max-lg:flex-col justify-between items-start mt-5 px-10">
                  <span className="font-inter text-[#000000] text-[26px] max-md:text-[22px] font-bold">
                    {name}
                  </span>
                  <div className="flex flex-row items-center gap-4 text-[#795EE3] text-[24px] max-md:text-[16px] font-bold mt-4">
                    <img
                      src={clock}
                      alt="clock"
                      className="size-6 max-md:size-4"
                    />
                    <span>{}</span>
                  </div>
                </div>
                <hr className="bg-[#795EE3] h-1 w-full my-4" />

                <div className="flex flex-col text-[20px] max-md:text-[16px] text-[#000000] font-inter font-bold text-justify gap-4 px-10">
                  <span>Date and Time Preferences</span>
                  <span className="font-medium text-[16px] max-md:text-[12px]">
                    Please let us know your preferable date and time?
                  </span>
                </div>

                <div className="flex flex-row gap-8 items-center px-10">
                  <Space direction="vertical" className="my-10">
                    <DatePicker
                      onChange={(date) => setGroupSelectedDate(date)}
                      size="large"
                      disabledDate={(current) =>
                        current &&
                        current < moment().add(7, "days").endOf("day")
                      }
                    />
                  </Space>

                  <Space wrap>
                    <TimePicker
                      use12Hours
                      format="h:mm a"
                      onChange={(time) => setGroupSelectedTime(time)}
                      size="large"
                    />
                  </Space>
                </div>

                <div className="flex flex-col text-[20px] max-md:text-[16px] font-inter font-bold text-justify font-medium gap-4 mb-10 px-10">
                  <span>Any special Requirement? (Optional)</span>
                  <span className="font-medium text-[16px] max-md:text-[12px]">
                    If you are having any specific or special requirement please
                    feel free to let us know
                  </span>
                  <textarea
                    className="rounded-[10px] bg-[#FFDBBB] p-2"
                    value={specialRequirement}
                    onChange={(e) => setSpecialRequirement(e.target.value)}
                  ></textarea>
                </div>

                {/*<GroupPujaAddon />*/}

                <div className="flex flex-row justify-around items-center pb-10 px-10">
                  {currentgrouppuja > 0 && (
                    <Button
                      label="Previous"
                      style={{
                        backgroundColor: "#FBD16E",
                        color: "black",
                        borderColor: "#FF8213",
                      }}
                      onClick={() => prevgroupstep()}
                    />
                  )}
                  {currentgrouppuja < grouppujastep.length - 1 && (
                    <Button
                      label="Next"
                      style={{
                        backgroundColor: "#FBD16E",
                        color: "black",
                        borderColor: "#FF8213",
                      }}
                      onClick={handleNext}
                    />
                  )}
                  {currentgrouppuja === grouppujastep.length - 1 && (
                    <Button
                      label="Review"
                      style={{
                        backgroundColor: "#FBD16E",
                        color: "black",
                        borderColor: "#FF8213",
                      }}
                      onClick={handleDone}
                    />
                  )}
                </div>
                <div className="block max-sm:hidden">
                  <FooterSmall />
                </div>
                <div className="hidden max-sm:block">
                  <Footer />
                </div>
              </div>
            </div>
          );
        })}
        <GroupPujaCartModal cartpujadata={cartgrouppujadata} />
      </div>
    </>
  );
}

export default GroupPujaDateTime;
