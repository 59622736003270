import React from "react";
import whatsapp from "../../assets/whatsapp.svg";

function Whatsapp() {
  return (
    <a
      href="https://wa.me/+919910738229"
      className="fixed bottom-0 right-0 m-4 z-50 transform transition-transform duration-300 hover:scale-110"
    >
      <img src={whatsapp} alt="whatsapp" className="size-12" />
    </a>
  );
}

export default Whatsapp;
