import React from "react";

function Policies() {
  return (
    <div className="flex flex-col max-md:flex-row max-md:items-center max-md:justify-around gap-4 font-sans text-[#00000] text-[20px] max-lg:text-[16px] font-normal non-italic">
      <h1 className="text-[22px] max-lg:text-[18px] font-bold">Policies</h1>
      <span>Privacy Policy</span>
      <span>Refund Policy</span>
      <span>Terms and Conditions</span>
    </div>
  );
}

export default Policies;
