import React, { useContext, useEffect } from "react";
import GroupPujaProgressSteps from "../../components/progresssteps/GroupPujaProgressSteps";
import { ContextData } from "../../context/Context";
import GroupPujaSearchCategory from "./sections/groupcategory/GroupPujaSearchCategory";

function GroupPujaPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { currentgrouppuja } = useContext(ContextData);

  return (
    <>
      <div>{currentgrouppuja === 0 ? <GroupPujaSearchCategory /> : null}</div>
      <GroupPujaProgressSteps />
    </>
  );
}

export default GroupPujaPage;
