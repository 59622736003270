import React from "react";
import Button from "../../../components/button/Button";

function CartDataCard({ data }) {
  const handlemoredetail = () => {};

  const { name, pujaImageURL, total, mode, homePuja } = data;
  const pujaImage = pujaImageURL[0].toLowerCase();
  const pujaimagearray = pujaImage.split("/");
  const imageURL = `https://${pujaimagearray[2]}.s3.amazonaws.com/${pujaimagearray[3]}/${pujaimagearray[4]}/${pujaimagearray[5]}`;
  return (
    <div className="border rounded-[10px] flex flex-row justify-between items-center bg-[#fff1cf]">
      <div className="flex flex-row gap-4 items-center">
        <img src={imageURL} alt={name} className="size-48 rounded-[10px]" />
        <div className="flex flex-col gap-2 text-[16px] font-inter font-bold text-[#FF8213] py-4">
          <div className="text-[18px] font-inter font-semibold text-[#000000]">
            Name: <span className=" font-bold text-[#FF8213]">{name}</span>
          </div>
          <div>
            <span className="font-semibold text-[#000000]">Mode:</span> {mode}
          </div>
          <div className="mb-2">
            <span className="font-semibold text-[#000000]">Package Name:</span>{" "}
            {homePuja.name[0]}
          </div>
          <Button
            label="More Details"
            style={{
              backgroundColor: "transparent",
              color: "black",
              borderColor: "#FF8213",
            }}
            onClick={() => handlemoredetail()}
          />
        </div>
      </div>
      <div className="text-[18px] font-inter font-semibold text-[#000000] pr-10 py-4">
        Price : <span className="font-bold text-[#FF8213]">₹ {total}</span>
      </div>
    </div>
  );
}

export default CartDataCard;
