import React from "react";
import facebook from "../../../assets/facebook.svg";
import instagram from "../../../assets/instagram.svg";
import pinterest from "../../../assets/pinterest.svg";
import linkedin from "../../../assets/linkedin.svg";
import youtube from "../../../assets/youtube.svg";
import LazyLoad from "react-lazyload";

function Socials() {
  return (
    <div className="flex flex-col gap-4">
      <h1 className="text-[20px] max-lg:text-[18px] max-sm:text-[14px] font-semibold text-[#000000] non-italic font-sans">
        Follow us on:
      </h1>
      <div className="flex flex-row items-center flex-wrap gap-8">
        <LazyLoad height={20}>
          <img src={facebook} alt="facebook" className="size-8 max-sm:size-6" />
        </LazyLoad>
        <LazyLoad height={20}>
          <img
            src={instagram}
            alt="instagram"
            className="size-8 max-sm:size-6"
          />
        </LazyLoad>
        <LazyLoad height={20}>
          <img
            src={pinterest}
            alt="pinterest"
            className="size-8 max-sm:size-6"
          />
        </LazyLoad>
        <LazyLoad height={20}>
          <img src={linkedin} alt="linkedin" className="size-8 max-sm:size-6" />
        </LazyLoad>
        <LazyLoad height={20}>
          <img src={youtube} alt="youtube" className="size-10 max-sm:size-8" />
        </LazyLoad>
      </div>
    </div>
  );
}

export default Socials;
