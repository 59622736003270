import React, { useContext, useEffect } from "react";
import { ContextData } from "../../context/Context";
import CartDataCard from "./section/CartDataCard";
import Footer from "../../components/footer/Footer";
import Button from "../../components/button/Button";
import CartTemplePujaCard from "./section/CartTemplePujaCard";

function CartPage() {
  const {
    finalcartpujadata,
    pujatotal,
    setPujaTotal,
    templepujatotal,
    setTemplePujaTotal,
    finaltemplecartpujadata,
  } = useContext(ContextData);

  useEffect(() => {
    const total = finalcartpujadata.reduce(
      (acc, item) => acc + (item.total || 0),
      0
    );
    setPujaTotal(total);
  }, [finalcartpujadata]);

  useEffect(() => {
    const total = finaltemplecartpujadata.reduce(
      (acc, item) => acc + (item.finaltotal || 0),
      0
    );
    setTemplePujaTotal(total);
  }, [finaltemplecartpujadata]);

  return (
    <>
      {finalcartpujadata.length > 0 || finaltemplecartpujadata.length > 0 ? (
        <div className="px-[8%] py-10 flex flex-col gap-6">
          <div className="text-[32px] font-inter font-bold text-[#000000]">
            Cart
          </div>
          {finalcartpujadata && finalcartpujadata.length > 0 ? (
            <>
              <div className="text-[26px] font-inter font-bold text-[#000000]">
                Pujas
              </div>
              <div className="flex flex-col gap-8">
                {finalcartpujadata.map((item, index) => (
                  <div key={index}>
                    <CartDataCard data={item} />
                  </div>
                ))}
              </div>
            </>
          ) : null}

          {finaltemplecartpujadata && finaltemplecartpujadata.length > 0 ? (
            <>
              <div className="text-[26px] font-inter font-bold text-[#000000]">
                Temple Pujas
              </div>
              <div className="flex flex-col gap-8">
                {finaltemplecartpujadata.map((item, index) => {
                  const { templeName, templeImage, templeLocation, pujas } =
                    item;

                  const pujaImage =
                    templeImage && templeImage[0]
                      ? templeImage[0].toLowerCase()
                      : null;
                  let imageURL;

                  if (pujaImage) {
                    const pujaimagearray = pujaImage.split("/");
                    imageURL = `https://${pujaimagearray[2]}.s3.amazonaws.com/${pujaimagearray[3]}/${pujaimagearray[4]}/${pujaimagearray[5]}`;
                  } else {
                    imageURL = "";
                  }

                  return (
                    <div
                      key={index}
                      className="border rounded-[10px] flex flex-row justify-between items-center bg-[#fff1cf]"
                    >
                      <div className="flex flex-col gap-4">
                        <div className="flex flex-row gap-4 items-center">
                          <img
                            src={imageURL}
                            alt={templeName}
                            className="size-40 rounded-[10px]"
                          />
                          <div className="flex flex-col gap-4 text-[16px] font-inter font-bold text-[#FF8213] py-4">
                            <div>
                              <div className="text-[18px] font-inter font-bold text-[#FF8213]">
                                {templeName}
                              </div>
                              <div className="text-[18px] font-inter font-bold text-[#FF8213]">
                                {templeLocation}
                              </div>
                            </div>
                            <div>
                              {pujas.map((item, index) => (
                                <div key={index}>
                                  <CartTemplePujaCard data={item} />
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="w-full flex justify-center pb-5">
                          <Button
                            label="More Details"
                            style={{
                              backgroundColor: "transparent",
                              color: "black",
                              borderColor: "#FF8213",
                            }}
                          />
                        </div>
                      </div>
                      <div className="text-[18px] font-inter font-semibold text-[#000000] pr-10 py-4">
                        Price :{" "}
                        <span className="font-bold text-[#FF8213]">
                          ₹ {finaltemplecartpujadata[0].finaltotal}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="text-[18px] font-inter font-semibold text-[#000000] w-full flex justify-end">
                Total Cost :{" "}
                <span className=" font-bold text-[#FF8213]">
                  ₹ {pujatotal + templepujatotal}
                </span>
              </div>
              <div className="w-full flex justify-center">
                <Button
                  label="Pay"
                  style={{
                    backgroundColor: "transparent",
                    color: "black",
                    borderColor: "#FF8213",
                  }}
                />
              </div>
            </>
          ) : null}
        </div>
      ) : (
        <div className="h-full">
          <div className="font-inter text-[#000000] text-[28px] text-center max-md:text-[24px] font-bold flex w-full h-80 items-center justify-center">
            Embrace the Divine with RitiVidhi’s Sacred Rituals! <br />
            Add Pujas to get blessing
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}

export default CartPage;
