import React from "react";
import Button from "../button/Button";

function SearchOrder() {
  return (
    <div className="flex flex-row gap-4 self-start max-md:mb-5">
      <input
        type="text"
        placeholder="Search all orders"
        className="px-16 py-1 max-md:px-8 max-sm:px-4 bg-transparent rounded-[20px] border border-[#000000] w-full"
      />
      <Button
        label="Search"
        style={{
          backgroundColor: "#FFF1D0",
          color: "black",
          borderColor: "black",
        }}
      />
    </div>
  );
}

export default SearchOrder;
