import React from "react";
import Button from "../button/Button";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";

function LandingPageCard({
  name,
  pujaImageURL,
  description,
  buttonlabel,
  time,
  link,
  onClick,
}) {
  const pujaImage = pujaImageURL[0].toLowerCase();
  const pujaimagearray = pujaImage.split("/");
  const imageurl = `https://${pujaimagearray[2]}.s3.amazonaws.com/${pujaimagearray[3]}/${pujaimagearray[4]}/${pujaimagearray[5]}`;

  const aboutpara = description.split(".");

  return (
    <div
      className="border border-2 border-[#B8F3DE] flex flex-col gap-2 max-sm:gap-0 rounded-[20px] max-sm:rounded-[10px] h-full cursor-pointer"
      style={{
        background: "linear-gradient(180deg, #FFF 20.5%, #EAF5F1 43.5%)",
      }}
      onClick={onClick}
    >
      <div className="p-4 flex flex-col gap-4">
        <h1 className="text-[26px] max-md:text-[16px] max-sm:text-[12px] font-sans non-italic font-bold text-[#000000]">
          {name}
        </h1>
        <p className="text-[16px] font-sans non-italic font-normal text-[#000000] mb-4 block max-sm:hidden">
          {`${aboutpara[0]}.`}
        </p>

        {time ? (
          <div className="w-full flex justify-around items-center max-md:hidden">
            <div>
              <Button
                label={buttonlabel}
                style={{ backgroundColor: "#FFF1D0", color: "black" }}
                onClick={onClick}
              />
            </div>
            <span className="text-[#595959] font-sans text-[16px] non-italic font-bold">
              {time}
            </span>
          </div>
        ) : (
          <div className="block max-md:hidden">
            <div>
              <Button
                label={buttonlabel}
                style={{ backgroundColor: "#FFF1D0", color: "black" }}
                onClick={onClick}
              />
            </div>
          </div>
        )}
      </div>
      <LazyLoad height={100} offset={100}>
        <img src={imageurl} alt={name} className="rounded-b-[10px]" />
      </LazyLoad>
    </div>
  );
}

export default LandingPageCard;
