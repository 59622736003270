import React from "react";

function CartTemplePujaCard({ data }) {
  const { name, mode, homePuja } = data;
  return (
    <div className="flex flex-row justify-between items-center">
      <div className="flex flex-col gap-2 text-[16px] font-inter font-bold text-[#FF8213] py-4">
        <div className="text-[18px] font-inter font-semibold text-[#000000]">
          Puja Name: <span className=" font-bold text-[#FF8213]">{name}</span>
        </div>
        <div>
          <span className="font-semibold text-[#000000]">Mode:</span> {mode}
        </div>
        <div className="mb-2">
          <span className="font-semibold text-[#000000]">Package Name:</span>{" "}
          {homePuja.name[0]}
        </div>
      </div>
    </div>
  );
}

export default CartTemplePujaCard;
