import React from "react";
import Button from "../../../components/button/Button";

function Subscribe() {
  return (
    <div className="px-[4%] py-16">
      <div
        className="flex flex-col gap-4 rounded-[20px] px-4 py-8"
        style={{ background: "linear-gradient(90deg, #94D0EE 0%, #FFF 100%)" }}
      >
        <Button
          label="Subscribe"
          style={{
            backgroundColor: "#F8A861",
            color: "black",
          }}
        />
        <h1 className="text-[#000000] font-sans text-[32px] max-sm:text-[12px] non-italic font-bold">
          Stay Connected. Preserve Legacy.
        </h1>
        <p className="text-[#000000] font-sans text-[20px] max-sm:text-[12px] non-italic font-normal mb-4">
          Subscribe to Our Newsletter and Embark on a Journey to Honor and
          Preserve Our Timeless Legacy
        </p>
        <div className="flex flex-row items-center gap-8">
          <input
            type="email"
            placeholder="example@username.com"
            className="border border-[#938D8D] rounded-[10px] bg-[#FFF1D0] px-4 py-2 w-1/3 max-sm:w-full placeholder-[16px] placeholder-font-sans placeholder-non-italic placeholder-text-[#7B7575] placeholder-font-normal"
          />
          <Button
            label="Subscribe"
            style={{
              backgroundColor: "#FFF1D0",
              color: "black",
              borderColor: "#000000",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Subscribe;
