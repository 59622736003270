import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ContextData } from "../../../context/Context";

function NavigationItems() {
  const { setCurrentPuja, setCurrentTemplePuja, setCurrentGroupPuja } =
    useContext(ContextData);
  const location = useLocation();

  return (
    <div className="flex flex-row gap-4 items-center text-[#595959] font-sans text-[16px] font-semibold leading-6 not-italic">
      <NavLink to="/">
        <button>Home</button>
      </NavLink>
      <NavLink to="/product" onClick={() => setCurrentPuja(0)}>
        <button>Book a Pooja</button>
      </NavLink>
      <NavLink to="/temple" onClick={() => setCurrentTemplePuja(0)}>
        <button>Temple Services</button>
      </NavLink>
      <NavLink to="/group" onClick={() => setCurrentGroupPuja(0)}>
        <button>Group Puja</button>
      </NavLink>
      {location.pathname === "/" && (
        <a href="#about">
          <button>About us</button>
        </a>
      )}
      <NavLink to="/blog">
        <button>Read</button>
      </NavLink>
      <NavLink to="/cart">
        <button>Cart</button>
      </NavLink>
    </div>
  );
}

export default NavigationItems;
