import React from "react";

function PujaSamagri({ samagri }) {
  const { havan, puja, yajman } = samagri;
  return (
    <div>
      <div className="flex flex-col gap-8">
        <span className="text-[20px] max-md:text-[18px] max-sm:text-[12px] font-sans non-italic font-semibold text-[#000000]">
          Havan
        </span>
        <div className="flex flex-row flex-wrap gap-4">
          {havan.map((items, index) => {
            return (
              <div
                key={index}
                className="border-2 border-[#FF8213] text-[#000000] px-2 py-1 rounded-[10px]"
              >
                {items}
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex flex-col gap-8 mt-10">
        <span className="text-[20px] max-md:text-[18px] max-sm:text-[12px] font-sans non-italic font-semibold text-[#000000]">
          Puja
        </span>
        <div className="flex flex-row flex-wrap gap-4">
          {puja.map((items, index) => {
            return (
              <div
                key={index}
                className="border-2 border-[#FF8213] text-[#000000] px-2 py-1 rounded-[10px]"
              >
                {items}
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex flex-col gap-8 mt-10">
        <span className="text-[20px] max-md:text-[18px] max-sm:text-[12px] font-sans non-italic font-semibold text-[#000000]">
          Yajman
        </span>
        <div className="flex flex-row flex-wrap gap-4">
          {yajman.map((items, index) => {
            return (
              <div
                key={index}
                className="border-2 border-[#FF8213] text-[#000000] px-2 py-1 rounded-[10px]"
              >
                {items}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default PujaSamagri;
