import React from "react";

function InfiniteAnimationLeft() {
  return (
    <div className="z-10 flex space-x-8 max-sm:space-x-4 overflow-hidden bg-[#e9e9e9]/40 py-8">
      <div className="flex space-x-8 max-sm:space-x-4 animate-scrollleft whitespace-nowrap">
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Convenience
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Flexibility
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Inclusivity
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Efficiency
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Innovation
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Accessibility
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Personalization
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Global Reach
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Affordability
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Holistic Approach
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Adaptability
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Innovation
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Simplicity
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Inclusiveness
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Evolution
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Practicality
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Customization
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Connection
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Sustainability
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Progressive
        </span>
      </div>

      <div
        className="flex space-x-8 max-sm:space-x-4 animate-scrollleft whitespace-nowrap"
        aria-hidden="true"
      >
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Convenience
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Flexibility
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Inclusivity
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Efficiency
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Innovation
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Accessibility
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Personalization
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Global Reach
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Affordability
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Holistic Approach
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Adaptability
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Innovation
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Simplicity
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Inclusiveness
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Evolution
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Practicality
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Customization
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Connection
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Sustainability
        </span>
        <span className="border px-8 py-2 rounded-[50px] bg-[#D9D9D9] text-[16px] max-md:text-[14px] max-sm:text-[12px] text-[#000000] font-bold font-sans no-wrap flex-shrink-0">
          Progressive
        </span>
      </div>
    </div>
  );
}

export default InfiniteAnimationLeft;
