import React, { useEffect } from "react";
import PujaSummaryCard from "./sections/PujaSummaryCard";
import Footer from "../../../../components/footer/Footer";
import PujaAddressCard from "./sections/PujaAddressCard";
import PujaPaymentCard from "./sections/PujaPaymentCard";
import { Breadcrumb } from "antd";

function OrderDetail() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="px-[16%] max-md:px-[8%] py-5 flex flex-col gap-4">
        <Breadcrumb
          items={[
            {
              title: (
                <a
                  href="/"
                  className="font-inter font-medium text-[14px] text-[#000000]"
                >
                  Home
                </a>
              ),
            },
            {
              title: (
                <a
                  href="/user"
                  className="font-inter font-medium text-[14px] text-[#000000]"
                >
                  Profile
                </a>
              ),
            },
            {
              title: (
                <a
                  href="/order"
                  className="font-inter font-medium text-[14px] text-[#000000]"
                >
                  Order History
                </a>
              ),
            },
            {
              title: (
                <a
                  href="/orderdetail"
                  className="font-inter font-medium text-[14px] text-[#000000]"
                >
                  Order Details
                </a>
              ),
            },
          ]}
          className="mb-5"
        />
        <h1 className="text-[34px] max-md:text-[24px] font-inter text-[#000000] font-bold">
          Order Details
        </h1>
        <div className="mb-4 text-[18px] max-md:text-[12px] font-inter text-[#000000] font-semibold flex flex-row max-md:flex-col gap-4">
          <span>Order placed on 21 October 2024</span>
          <span>Order # 402-9961068-1509932</span>
        </div>
        <div className="flex flex-col gap-4">
          <PujaSummaryCard />
          <PujaPaymentCard />
          <PujaAddressCard />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OrderDetail;
