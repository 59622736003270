import React from "react";
import LazyLoad from "react-lazyload";
import mail from "../../assets/mail.svg";
import event from "../../assets/event.svg";
import calender from "../../assets/calender.svg";

function UserProfileForm() {
  return (
    <LazyLoad height={100} offset={100}>
      <form>
        <hr className="h-1 bg-[#C4C4C4] mb-8" />
        <div className="flex flex-row justify-around max-md:flex-col max-md:gap-4">
          <div className="flex flex-col flex-1 items-start gap-2">
            <span className="font-inter text-[24px] max-md:text-[20px] text-[#000000] font-bold">
              Username
            </span>
            <span className="font-inter text-[20px] max-md:text-[16px] text-[#000000] font-normal">
              This will be displayed on your profile.
            </span>
          </div>
          <div className="flex flex-col flex-1 items-start gap-2">
            <div className="flex flex-row justify-around gap-4 border border-[#000000] w-2/3 max-md:w-full p-2 rounded-[10px] focus:outline-none">
              <input
                type="text"
                className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[14px] placeholder:font-medium placeholder:font-inter px-4 py-1"
                placeholder="Full name"
              />
            </div>

            <div className="flex flex-row justify-around gap-4 border border-[#000000] w-2/3 max-md:w-full p-2 rounded-[10px] focus:outline-none">
              <input
                type="text"
                className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[14px] placeholder:font-medium placeholder:font-inter px-4 py-1"
                placeholder="Last name"
              />
            </div>
          </div>
        </div>

        <hr className="h-1 bg-[#C4C4C4] my-8" />
        <div className="flex flex-row justify-around max-md:flex-col max-md:gap-4">
          <div className="flex flex-col items-start flex-1 gap-2">
            <span className="font-inter text-[24px] max-md:text-[20px] text-[#000000] font-bold">
              Profile Photo
            </span>
            <span className="font-inter text-[20px] max-md:text-[16px] text-[#000000] font-normal">
              Update your profile picture to get displayed on your profile.
            </span>
          </div>
          <div className="flex flex-col items-start flex-1 gap-2">
            <input type="text" placeholder="First name" />
            <input type="text" placeholder="Last name" />
          </div>
        </div>

        <hr className="h-1 bg-[#C4C4C4] my-8" />
        <div className="flex flex-row justify-around max-md:flex-col max-md:gap-4">
          <div className="flex flex-col flex-1 items-start gap-2">
            <span className="font-inter text-[24px] max-md:text-[20px] text-[#000000] font-bold">
              Contact Information
            </span>
            <span className="font-inter text-[20px] max-md:text-[16px] text-[#000000] font-normal">
              Update your contact information
            </span>
          </div>
          <div className="flex flex-col items-start flex-1 gap-2">
            <div className="flex flex-row justify-around border border-[#000000] w-2/3 max-md:w-full p-2 rounded-[10px] focus:outline-none">
              <img src={mail} alt="mail" className="size-8" />
              <input
                type="text"
                className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[14px] placeholder:font-medium placeholder:font-inter px-4 py-1"
                placeholder="Event Name for example Birthday, Anniversary etc."
              />
            </div>
            <div className="flex flex-row justify-around border border-[#000000] w-2/3 max-md:w-full p-2 rounded-[10px] focus:outline-none">
              <img src={mail} alt="mail" className="size-8" />
              <input
                type="text"
                className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[14px] placeholder:font-medium placeholder:font-inter px-4 py-1"
                placeholder="Please click here to select the day"
              />
            </div>
          </div>
        </div>

        <hr className="h-1 bg-[#C4C4C4] my-8" />
        <div className="flex flex-row justify-around max-md:flex-col max-md:gap-4">
          <div className="flex flex-col flex-1 items-start gap-2">
            <span className="font-inter text-[24px] max-md:text-[20px] text-[#000000] font-bold">
              News letter
            </span>
            <span className="font-inter text-[20px] max-md:text-[16px] text-[#000000] font-normal">
              Sign up for our news letter.
            </span>
          </div>
          <div className="flex flex-row flex-1 items-center gap-4">
            <input
              type="checkbox"
              id="newsletter"
              name="newsletter"
              className="size-6"
            />
            <label
              htmlFor="newsletter"
              className="bg-transparent w-full text-[#000000] text-[20px] max-md:text-[16px] font-medium font-inter px-4 py-1"
            >
              Signup for our blogs/newsletters
            </label>
          </div>
        </div>

        <hr className="h-1 bg-[#C4C4C4] my-8" />
        <div className="flex flex-row justify-around max-md:flex-col max-md:gap-4">
          <div className="flex flex-col flex-1 items-start gap-2">
            <span className="font-inter text-[24px] max-md:text-[20px] text-[#000000] font-bold">
              Special Events/ Dates
            </span>
            <span className="font-inter text-[20px] max-md:text-[16px] text-[#000000] font-normal w-2/3 max-md:w-full">
              Share your important/special dates with us to get an exclusive
              offers
            </span>
          </div>
          <div className="flex flex-col items-start flex-1 gap-2">
            <div className="flex flex-row justify-around border border-[#000000] w-2/3 max-md:w-full p-2 rounded-[10px] focus:outline-none">
              <img src={event} alt="event" />
              <input
                type="text"
                className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[14px] placeholder:font-medium placeholder:font-inter px-4 py-1"
                placeholder="Event Name for example Birthday, Anniversary etc."
              />
            </div>
            <div className="flex flex-row justify-around border border-[#000000] w-2/3 max-md:w-full p-2 rounded-[10px] focus:outline-none">
              <img src={calender} alt="calender" />
              <input
                type="text"
                className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[14px] placeholder:font-medium placeholder:font-inter px-4 py-1"
                placeholder="Please click here to select the day"
              />
            </div>
          </div>
        </div>
      </form>
    </LazyLoad>
  );
}

export default UserProfileForm;
