import React from "react";
import Button from "../../button/Button";

function SubscribeInput() {
  return (
    <div className="flex flex-row gap-4 items-center">
      <div className="border-b-[1px] border-[#000000] py-2 px-4">
        <input
          type="text"
          placeholder="Please enter your email here"
          className="w-full py-2 bg-transparent placeholder-text-[#595959] placeholder-text-[20px] placeholder-font-sans placeholder-not-italic placeholder-font-semibold focus:outline-none"
        />
      </div>
      <Button
        label="Subscribe"
        style={{
          backgroundColor: "#FFF1D0",
          color: "black",
          borderColor: "#000000",
        }}
      />
    </div>
  );
}

export default SubscribeInput;
