import { Modal } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import { ContextData } from "../../context/Context";
import ritividhi from "../../assets/ritividhilogo.png";
import cross from "../../assets/cross.svg";
import LazyLoad from "react-lazyload";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  GoogleAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../../firebase/setup";
import OTPInput from "react-otp-input";
import google from "../../assets/google.png";

function LoginAccountModal() {
  const { openloginmodal, setOpenloginmodal, setLoggedIn, login, setLogin } =
    useContext(ContextData);

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errors, setErrors] = useState({});
  const [OTP, setOTP] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [count, setCount] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const handleClose = () => {
    setOpenloginmodal(false);
  };

  const validateSignUpInputs = () => {
    let inputErrors = {};

    if (!name.trim()) {
      inputErrors.name = "Name is required";
    }
    if (!/^\+91\d{10}$/.test(phoneNumber)) {
      inputErrors.phoneNumber =
        "Phone number must be in the format +91(Number)";
    }

    setErrors(inputErrors);
    return Object.keys(inputErrors).length === 0;
  };

  const validateLoginInputs = () => {
    let inputErrors = {};
    if (!/^\+91\d{10}$/.test(phoneNumber)) {
      inputErrors.phoneNumber =
        "Phone number must be in the format +91(Number)";
    }

    setErrors(inputErrors);
    return Object.keys(inputErrors).length === 0;
  };

  const handlegoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        setLoggedIn(true);
        setPhoneNumber("");
        handleClose();
        setOTP("");
        setShowOTP(false);
      })
      .catch((error) => {
        console.error("Login failed:", error);
      });
  };

  const startTimer = () => {
    setIsActive(true);
  };

  useEffect(() => {
    let timer;
    if (isActive && count > 0) {
      timer = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isActive, count]);

  const onCaptchVerify = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, "sign-in-button", {
        size: "invisible",
        callback: () => {
          window.recaptchaVerifier.recaptcha.reset();
        },
        "expired-callback": () => {
          window.recaptchaVerifier.recaptcha.reset();
        },
        "error-callback": () => {
          window.recaptchaVerifier.recaptcha.reset();
        },
      });
    }
  };

  const onSignup = () => {
    onCaptchVerify();
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setShowOTP(true);
        setCount(60);
        startTimer();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleback = () => {
    setOTP("");
    setPhoneNumber("");
    setShowOTP(false);
  };

  const handleResend = () => {
    setOTP("");
    onSignup();
  };

  const onOTPVerify = () => {
    window.confirmationResult
      .confirm(OTP)
      .then(async (res) => {
        setLoggedIn(true);
        setPhoneNumber("");
        handleClose();
        setOTP("");
        setShowOTP(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleLogin = () => {
    if (validateLoginInputs()) {
      onSignup();
    }
  };

  const handleSignUp = () => {
    if (validateSignUpInputs()) {
      onSignup();
    }
  };

  const handleSignupChange = () => {
    setErrors({});
    setPhoneNumber("");
    setName("");
    setLogin(!login);
  };

  return (
    <>
      <div id="sign-in-button"></div>
      {login ? (
        <Modal
          open={openloginmodal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableEnforceFocus
          className="bg-[#00000099]"
        >
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[400px] bg-white border-2 border-[#FF8213CC] shadow-xl px-8 py-6 rounded-[10px]">
            <div id="modal-modal-description" className="flex flex-col gap-4">
              <div className="w-full flex flex-row justify-between items-center">
                <LazyLoad height={20}>
                  <img
                    src={ritividhi}
                    alt="RitiVidhi logo"
                    className="size-20"
                  />
                </LazyLoad>
                <LazyLoad height={20}>
                  <img
                    src={cross}
                    alt="Close"
                    className="size-8 cursor-pointer"
                    onClick={handleClose}
                  />
                </LazyLoad>
              </div>
              {showOTP ? (
                <>
                  <h1 className="font-sans text-[20px] text-[#000000] font-bold">
                    Login to check your booking
                  </h1>
                  <span className="pt-2 text-BLACK-60 text-[12px] w-full font-normal text-left mb-4">
                    Please login with the same number that you have used for
                    booking.
                  </span>
                  <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    numInputs={6}
                    otpType="number"
                    disabled={false}
                    renderSeparator={<span className="mx-2">-</span>}
                    renderInput={(props) => (
                      <input
                        {...props}
                        className="flex-1 mx-1 text-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    )}
                  />

                  <div className="w-full flex justify-center text-[20px]">
                    {count}
                  </div>

                  {errors.phoneNumber && (
                    <span className="text-red-500 text-[12px]">
                      {errors.phoneNumber}
                    </span>
                  )}
                  <span className="font-sans text-[14px] font-normal text-[#000000]">
                    By proceeding you agree to the <br />
                    <span className="text-[#FF8213]">
                      Terms & Conditions
                    </span>{" "}
                    and <span className="text-[#FF8213]">Privacy Policy</span>{" "}
                    of <span className="text-[#FF8213]">RitiVidhi</span>
                  </span>

                  {count < 1 ? (
                    <div className="flex flex-row justify-center items-center gap-4">
                      <button
                        onClick={() => handleback()}
                        className="w-full py-3 flex justify-center rounded-[10px] text-[18px] text-[#ffffff] bg-[#FF8213]"
                      >
                        Back
                      </button>
                      <button
                        onClick={() => handleResend()}
                        className="w-full py-3 flex justify-center rounded-[10px] text-[18px] text-[#ffffff] bg-[#FF8213]"
                      >
                        Resend OTP
                      </button>
                    </div>
                  ) : (
                    <div className="flex flex-row justify-center items-center gap-4">
                      <button
                        onClick={() => handleback()}
                        className="w-full py-3 flex justify-center rounded-[10px] text-[18px] text-[#ffffff] bg-[#FF8213]"
                      >
                        Back
                      </button>
                      <button
                        className="w-full py-3 flex justify-center rounded-[10px] text-[18px] text-[#ffffff] bg-[#FF8213]"
                        onClick={onOTPVerify}
                      >
                        Verfiy
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <h1 className="font-sans text-[20px] text-[#000000] font-bold">
                    Login to check your booking
                  </h1>
                  <span className="pt-2 text-BLACK-60 text-[12px] w-full font-normal text-left mb-4">
                    Please login with the same number that you have used for
                    booking.
                  </span>
                  <PhoneInput
                    country={"in"}
                    value={phoneNumber}
                    onChange={(phoneNumber) =>
                      setPhoneNumber("+" + phoneNumber)
                    }
                    inputProps={{
                      placeholder: "Phone Number",
                      className:
                        "py-2 px-4 rounded-[10px] border border-[#FF8213] w-full pl-12",
                    }}
                  />

                  {errors.phoneNumber && (
                    <span className="text-red-500 text-[12px]">
                      {errors.phoneNumber}
                    </span>
                  )}
                  <span className="font-sans text-[14px] font-normal text-[#000000]">
                    By proceeding you agree to the <br />
                    <span className="text-[#FF8213]">
                      Terms & Conditions
                    </span>{" "}
                    and <span className="text-[#FF8213]">Privacy Policy</span>{" "}
                    of <span className="text-[#FF8213]">RitiVidhi</span>
                  </span>
                  <button
                    className="w-full py-3 flex justify-center rounded-[10px] text-[18px] text-[#ffffff] bg-[#FF8213]"
                    onClick={handleLogin}
                  >
                    Login
                  </button>
                </>
              )}
              <div className="text-center">
                <button
                  className="w-full py-2 border border-gray-300 rounded-full flex items-center justify-center space-x-2 text-gray-600 hover:bg-orange-500 hover:text-[#ffffff]"
                  onClick={handlegoogle}
                >
                  <img src={google} alt="Google" className="size-5" />
                  <span>Continue with Google</span>
                </button>
              </div>
              <div className="font-sans text-[14px] font-normal text-[#000000]">
                Create an account?{" "}
                <span
                  className="text-[#FF8213] cursor-pointer"
                  onClick={handleSignupChange}
                >
                  Sign Up
                </span>
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <Modal
          open={openloginmodal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableEnforceFocus
          className="bg-[#00000099]"
        >
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[400px] bg-white border-2 border-[#FF8213CC] shadow-xl px-8 py-6 rounded-[10px]">
            <div id="modal-modal-description" className="flex flex-col gap-4">
              <div className="w-full flex flex-row justify-between items-center">
                <LazyLoad height={20}>
                  <img
                    src={ritividhi}
                    alt="RitiVidhi logo"
                    className="size-20"
                  />
                </LazyLoad>
                <LazyLoad height={20}>
                  <img
                    src={cross}
                    alt="Close"
                    className="size-8 cursor-pointer"
                    onClick={handleClose}
                  />
                </LazyLoad>
              </div>
              {showOTP ? (
                <>
                  <h1 className="font-sans text-[20px] text-[#000000] font-bold">
                    Sign Up to create your account
                  </h1>
                  <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    numInputs={6}
                    otpType="number"
                    disabled={false}
                    renderSeparator={<span className="mx-2">-</span>}
                    renderInput={(props) => (
                      <input
                        {...props}
                        className="flex-1 mx-1 text-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    )}
                  />

                  <div className="w-full flex justify-center text-[20px]">
                    {count}
                  </div>

                  {errors.phoneNumber && (
                    <span className="text-red-500 text-[12px]">
                      {errors.phoneNumber}
                    </span>
                  )}
                  <span className="font-sans text-[14px] font-normal text-[#000000]">
                    By proceeding you agree to the <br />
                    <span className="text-[#FF8213]">
                      Terms & Conditions
                    </span>{" "}
                    and <span className="text-[#FF8213]">Privacy Policy</span>{" "}
                    of <span className="text-[#FF8213]">RitiVidhi</span>
                  </span>

                  {count < 1 ? (
                    <div className="flex flex-row justify-center items-center gap-4">
                      <button
                        onClick={() => handleback()}
                        className="w-full py-3 flex justify-center rounded-[10px] text-[18px] text-[#ffffff] bg-[#FF8213]"
                      >
                        Back
                      </button>
                      <button
                        onClick={() => handleResend()}
                        className="w-full py-3 flex justify-center rounded-[10px] text-[18px] text-[#ffffff] bg-[#FF8213]"
                      >
                        Resend OTP
                      </button>
                    </div>
                  ) : (
                    <div className="flex flex-row justify-center items-center gap-4">
                      <button
                        onClick={() => handleback()}
                        className="w-full py-3 flex justify-center rounded-[10px] text-[18px] text-[#ffffff] bg-[#FF8213]"
                      >
                        Back
                      </button>
                      <button
                        className="w-full py-3 flex justify-center rounded-[10px] text-[18px] text-[#ffffff] bg-[#FF8213]"
                        onClick={onOTPVerify}
                      >
                        Verfiy
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <h1 className="font-sans text-[20px] text-[#000000] font-bold">
                    Sign Up to create your account
                  </h1>
                  <label>Full Name</label>
                  <input
                    type="text"
                    placeholder="Full Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="py-2 px-4 rounded-[10px] border border-[#FF8213]"
                  />
                  {errors.name && (
                    <span className="text-red-500 text-[12px]">
                      {errors.name}
                    </span>
                  )}
                  <PhoneInput
                    country={"in"}
                    value={phoneNumber}
                    onChange={(phoneNumber) =>
                      setPhoneNumber("+" + phoneNumber)
                    }
                    inputProps={{
                      placeholder: "Phone Number",
                      className:
                        "py-2 px-4 rounded-[10px] border border-[#FF8213] w-full pl-12",
                    }}
                  />
                  {errors.phoneNumber && (
                    <span className="text-red-500 text-[12px]">
                      {errors.phoneNumber}
                    </span>
                  )}
                  <span className="font-sans text-[14px] font-normal text-[#000000]">
                    By proceeding you agree to the <br />
                    <span className="text-[#FF8213]">
                      Terms & Conditions
                    </span>{" "}
                    and <span className="text-[#FF8213]">Privacy Policy</span>{" "}
                    of <span className="text-[#FF8213]">RitiVidhi</span>
                  </span>
                  <button
                    className="w-full py-3 flex justify-center rounded-[10px] text-[18px] text-[#ffffff] bg-[#FF8213]"
                    onClick={handleSignUp}
                  >
                    Sign Up
                  </button>
                </>
              )}
              <div className="text-center">
                <button
                  className="w-full py-2 border border-gray-300 rounded-full flex items-center justify-center space-x-2 text-gray-600 hover:bg-orange-500 hover:text-[#ffffff]"
                  onClick={handlegoogle}
                >
                  <img src={google} alt="Google" className="size-5" />
                  <span>Continue with Google</span>
                </button>
              </div>
              <div className="font-sans text-[14px] font-normal text-[#000000]">
                Already have an account?{" "}
                <span
                  className="text-[#FF8213] cursor-pointer"
                  onClick={handleSignupChange}
                >
                  Login
                </span>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default LoginAccountModal;
