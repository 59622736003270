import React, { useContext } from "react";
import { ContextData } from "../../../../../context/Context";

function PujaAddressCard() {
  const { orderdetail } = useContext(ContextData);

  return (
    <div className="border border-[#D9D9D9] rounded-[10px] px-8 py-4 flex flex-col gap-8 bg-[#fff1cf]">
      <div className="flex flex-row justify-between">
        <div className="flex flex-col gap-4">
          <span className="text-[20px] max-md:text-[16px] font-inter text-[#000000] font-bold">
            Address
          </span>
          <span className="text-[16px] max-md:text-[12px] font-inter text-[#000000] font-semibold">
            Puja on Saturday
          </span>
        </div>
        <div className="flex flex-col gap-4">
          <span className="text-[16px] max-md:text-[12px] font-inter text-[#000000] font-semibold">
            Address Type
          </span>
          <span className="text-[16px] max-md:text-[12px] font-inter text-[#067841] font-semibold">
            Home
          </span>
        </div>
      </div>
      <div className="flex flex-col max-md:items-start gap-2 mb-10">
        <div className="flex flex-row max-md:flex-col gap-2 items-center max-md:items-start">
          <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#000000]">
            Address:
          </span>
          <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#643EF9]">
            {orderdetail.orderAddress}
          </span>
        </div>
        <div className="flex flex-row max-md:flex-col gap-2 items-center max-md:items-start">
          <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#000000]">
            Puja Time:
          </span>
          <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#643EF9]">
            {orderdetail.orderPujaTime}
          </span>
        </div>
        <div className="flex flex-row max-md:flex-col gap-2 items-center max-md:items-start">
          <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#000000]">
            Contact Number:
          </span>
          <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#643EF9]">
            {orderdetail.number}
          </span>
        </div>
      </div>
    </div>
  );
}

export default PujaAddressCard;
