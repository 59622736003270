import React from "react";

function Button({ label, onClick, style }) {
  return (
    <button
      onClick={onClick}
      style={style}
      className="bg-[#FFF1D0] border rounded-[10px] py-2 px-8 text-[16px] text-[#000000] font-sans font-bold leading-6 w-fit whitespace-nowrap flex-shrink-0"
    >
      {label}
    </button>
  );
}

export default Button;
