import React, { useContext } from "react";
import { ContextData } from "../../../../../context/Context";

function TemplePujaCard({ pujas }) {
  const { setSelectedTemplePujadetail, setCurrentTemplePuja } =
    useContext(ContextData);

  const handleselectedtemplepuja = async (pujaId) => {
    setCurrentTemplePuja(2);
    try {
      const response = await fetch(
        "https://app.ritividhi.com/ritividhi/offline-puja"
      );
      const templepujadata = await response.json();
      const currenttemplepujadata = templepujadata.filter(
        (puja) => puja.pujaId === pujaId
      );

      const updatedaddonsquantity = currenttemplepujadata.map((add) => {
        const { addOns } = add;

        const updatedAddOns = addOns.map((addOn) => ({
          ...addOn,
          quantity: 0,
        }));
        return {
          ...add,
          addOns: updatedAddOns,
        };
      });

      const pujasummaryresponse = await fetch(
        "https://app.ritividhi.com/ritividhi/pujaSummary"
      );
      const pujadata = await pujasummaryresponse.json();
      const selectedtemplepujaimage = pujadata.filter(
        (puja) => puja.pujaId === pujaId
      );

      const mergedData = updatedaddonsquantity.map((item) => {
        const matchingImage = selectedtemplepujaimage.find(
          (pujaimage) => pujaimage.pujaId === item.pujaId
        );

        return {
          ...item,
          pujaImageURL: matchingImage ? matchingImage.pujaImageURL : null,
        };
      });

      setSelectedTemplePujadetail(mergedData);
    } catch (error) {
      console.error("Error fetching all group pujas summary", error);
    }
  };

  return (
    <div className="grid grid-cols-3 gap-4">
      {pujas.map((puja) => {
        const { pujaId, name, description } = puja;
        const aboutpara = description.split(".");
        return (
          <div
            className="w-full border-2 border-[#B8F3DE] rounded-[10px] cursor-pointer px-4 py-6 flex flex-col gap-4"
            style={{
              background: "linear-gradient(180deg, #FFF 20.5%, #EAF5F1 43.5%)",
            }}
            key={pujaId}
            onClick={() => handleselectedtemplepuja(pujaId)}
          >
            <span className="text-[22px] font-sans non-italic font-bold text-[#000000]">
              {name}
            </span>
            <span className="text-[16px] font-sans non-italic font-normal text-[#000000] mb-4 block max-sm:hidden">
              {aboutpara[0]}
            </span>
          </div>
        );
      })}
    </div>
  );
}

export default TemplePujaCard;
