import React, { useContext } from "react";
import { ContextData } from "../../../../context/Context";
import clock from "../../../../assets/clock.svg";
import PujaSamagri from "../../../productpage/sections/productinfo/sections/PujaSamagri";
import Button from "../../../../components/button/Button";
import { message } from "antd";
import FooterSmall from "../../../../components/footer/FooterSmall";
import Footer from "../../../../components/footer/Footer";
import TemplePujaMode from "./sections/TemplePujaMode";
import TemplePujaPackageSelection from "./sections/TemplePujaPackageSelection";

function CurrentTemplePuja() {
  const {
    selectedtemplepujadetail,
    currenttemplepuja,
    templepujastep,
    nexttemplestep,
    prevtemplestep,
    selectedtemplemode,
  } = useContext(ContextData);
  return (
    <div>
      {selectedtemplepujadetail.length > 0 && selectedtemplepujadetail ? (
        <>
          {selectedtemplepujadetail.map((selectedtemple) => {
            const {
              pujaId,
              name,
              description,
              mode,
              significance,
              homePuja,
              samagri,
            } = selectedtemple;

            return (
              <div
                key={pujaId}
                className="flex flex-row max-md:gap-0 max-md:flex-col"
              >
                <div className="flex-1">
                  <img
                    src=""
                    alt={name}
                    className="w-full h-screen border-t-4 border-r-4 border-[#795EE3] max-md:border-none max-sm:h-full"
                  />
                </div>
                <div className="flex-1 h-screen overflow-auto scrollbar-hide">
                  <div className="my-5 px-10">
                    <Button
                      label="Previous"
                      style={{
                        backgroundColor: "transparent",
                        color: "black",
                        borderColor: "#FF8213",
                      }}
                      onClick={() => prevtemplestep()}
                    />
                  </div>
                  <div className="flex flex-row max-lg:flex-col justify-between items-start mt-5 px-10">
                    <span className="font-inter text-[#000000] text-[32px] max-md:text-[28px] font-bold w-1/2">
                      {name}
                    </span>
                    <div className="flex flex-row items-center gap-4 text-[#795EE3] text-[24px] max-md:text-[16px] font-bold mt-4">
                      <img
                        src={clock}
                        alt="clock"
                        className="size-6 max-md:size-4"
                      />
                    </div>
                  </div>
                  <hr className="bg-[#795EE3] h-1 w-full my-4" />
                  <div className="text-[#000000] text-inter text-justify flex flex-col gap-4 mb-8 px-10">
                    <span className="text-[24px] font-bold">
                      Puja Description
                    </span>
                    <p className="text-[16px] font-normal">{description}</p>
                    <h1 className="text-[24px] font-bold">Significance</h1>
                    <p className="text-[16px] font-normal">{significance}</p>
                  </div>

                  <TemplePujaMode {...{ mode }} />

                  <div
                    className={`text-[#000000] text-inter text-justify flex flex-col gap-4 mb-8 px-10 ${
                      selectedtemplemode ? "block" : "hidden"
                    }`}
                  >
                    <span className="text-[24px] font-bold">
                      Package Description
                    </span>
                    {homePuja.packages.map((packagedetail, index) => (
                      <div key={index}>
                        <TemplePujaPackageSelection
                          {...{ pujaId, packagedetail }}
                        />
                      </div>
                    ))}
                  </div>

                  <div className="text-[#000000] text-inter text-justify flex flex-col gap-4 mb-8 px-10">
                    <span className="text-[24px] font-bold">Puja Samagri</span>
                    <PujaSamagri {...{ samagri }} />
                  </div>

                  <div className="flex flex-row justify-around items-center pb-10 px-10">
                    {currenttemplepuja > 0 && (
                      <Button
                        label="Previous"
                        style={{
                          backgroundColor: "#FBD16E",
                          color: "black",
                          borderColor: "#FF8213",
                        }}
                        onClick={() => prevtemplestep()}
                      />
                    )}
                    {currenttemplepuja < templepujastep.length - 1 && (
                      <Button
                        label="Next"
                        style={{
                          backgroundColor: "#FBD16E",
                          color: "black",
                          borderColor: "#FF8213",
                        }}
                        onClick={() => nexttemplestep()}
                      />
                    )}
                    {currenttemplepuja === templepujastep.length - 1 && (
                      <Button
                        label="Done"
                        style={{
                          backgroundColor: "#FBD16E",
                          color: "black",
                          borderColor: "#FF8213",
                        }}
                        onClick={() => message.success("Processing complete!")}
                      />
                    )}
                  </div>
                  <div className="block max-sm:hidden">
                    <FooterSmall />
                  </div>
                  <div className="hidden max-sm:block">
                    <Footer />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className="h-full">
          <div className="font-inter text-[#000000] text-[28px] max-md:text-[24px] font-bold flex w-full h-80 items-center justify-center">
            Loading your Puja...
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
}

export default CurrentTemplePuja;
