import React from "react";
import ServiceHeading from "../../../components/servicecards/ServiceHeading";
import Service1 from "../../../components/servicecards/Service1";
import Service2 from "../../../components/servicecards/Service2";
import Service3 from "../../../components/servicecards/Service3";
import Service5 from "../../../components/servicecards/Service5";
import Service4 from "../../../components/servicecards/Service4";
import ServiceContact from "../../../components/servicecards/ServiceContact";
import Service6 from "../../../components/servicecards/Service6";
import CardSwipe from "../../../components/cardswipe/CardSwipe";

function Services() {
  return (
    <>
      <div className="px-[8%] py-10 flex flex-col gap-8 h-full max-xl:hidden">
        <div className="flex flex-row gap-8">
          <ServiceHeading />
          <Service1 />
        </div>
        <div className="flex flex-row gap-8 h-full w-full">
          <div className="flex flex-col gap-8 w-2/3">
            <div className="flex flex-row gap-8">
              <Service2 />
              <Service3 />
            </div>
            <Service5 />
          </div>
          <div className="w-1/3">
            <Service4 />
          </div>
        </div>
        <div className="flex flex-row gap-8">
          <ServiceContact />
          <Service6 />
        </div>
      </div>

      <div className="px-[8%] h-full flex flex-col gap-8 hidden max-xl:flex max-md:hidden">
        <ServiceHeading />
        <div className="flex flex-row gap-8">
          <div className="flex-1">
            <Service1 />
          </div>
          <div className="flex-1">
            <Service2 />
          </div>
        </div>
        <Service3 />
        <div className="h-full flex flex-row gap-8">
          <div className="flex-1">
            <Service4 />
          </div>
          <div className="flex-1">
            <Service5 />
          </div>
        </div>
        <div className="flex flex-row gap-8">
          <div className="flex-1">
            <ServiceContact />
          </div>
          <div className="flex-1">
            <Service6 />
          </div>
        </div>
      </div>

      <div className="px-[20%] max-sm:px-[8%] py-10 hidden max-md:flex max-md:justify-center">
        <CardSwipe />
      </div>
    </>
  );
}

export default Services;
