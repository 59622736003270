import React from "react";
import Button from "../../../../../components/button/Button";

function PujaPaymentCard() {
  return (
    <>
      <div className="border border-[#D9D9D9] rounded-[10px] flex flex-col gap-8 bg-[#fff1cf]">
        <div className="flex flex-row justify-between items-center mb-5 px-8 py-4">
          <div className="flex flex-col gap-4">
            <span className="text-[20px] max-md:text-[14px] font-inter text-[#000000] font-bold">
              Payment Details
            </span>
            <span className="text-[16px] max-md:text-[12px] font-inter text-[#000000] font-semibold">
              Card: XXXXX8600
            </span>
          </div>
          <div className="flex flex-col gap-4">
            <span className="text-[16px] max-md:text-[12px] font-inter text-[#000000] font-semibold">
              Payment Status
            </span>
            <span className="text-[16px] max-md:text-[12px] font-inter text-[#067841] font-semibold">
              Paid
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-2 flex-1 px-8 py-4">
          <div className="flex flex-row justify-between items-center">
            <span className="font-inter text-[16px] max-md:text-[12px] font-semibold text-[#000000]">
              Puja
            </span>
            <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#000000]">
              Rs 20000.00
            </span>
          </div>
          <div className="flex flex-row justify-between items-center">
            <span className="font-inter text-[16px] max-md:text-[12px] font-semibold text-[#000000]">
              Add Ons
            </span>
            <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#000000]">
              Rs 1000.00
            </span>
          </div>
          <div className="flex flex-row justify-between items-center">
            <span className="font-inter text-[16px] max-md:text-[12px] font-semibold text-[#000000]">
              Total
            </span>
            <span className="font-inter text-[16px] max-md:text-[12px] font-medium text-[#000000]">
              Rs 20000.00
            </span>
          </div>
        </div>
        <Button
          label="Download Invoice"
          style={{
            backgroundColor: "tansparent",
            color: "black",
            borderColor: "black",
          }}
        />
      </div>
    </>
  );
}

export default PujaPaymentCard;
