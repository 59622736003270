import React from "react";
import LazyLoad from "react-lazyload";
import Button from "../button/Button";
import officeservice from "../../assets/landingpageicons/officeservice.gif";
import { Link } from "react-router-dom";

function Service5() {
  return (
    <div
      className="border border-2 border-[#FFD981] flex flex-row justify-center gap-4 rounded-[20px] p-4 h-full max-xl:flex-col max-xl:p-10"
      style={{ background: "linear-gradient(90deg, #F3E9D2 57.5%, #FFF 100%)" }}
    >
      <LazyLoad height={200}>
        <img
          src={officeservice}
          alt="officeservice"
          className="w-[550px] h-[200px] max-lg:w-[350px] scale-[1.5]"
        />
      </LazyLoad>
      <div className="flex flex-col gap-4">
        <h1 className="text-[28px] font-sans non-italic font-bold text-[#000000]">
          Corporate Services (Coming Soon...)
        </h1>
        <p className="text-[16px] font-sans non-italic font-normal text-[#000000] mb-4">
          Invite prosperity and success to your business with corporate poojas,
          including Vastu Shanti, Ganesh Poojan, and other rituals tailored to
          enhance positive energy in your workplace.
        </p>
        <Link to="/product">
          <Button
            label="Book a Pooja"
            style={{
              backgroundColor: "#FFF1D0",
              color: "black",
              borderColor: "#000000",
            }}
          />
        </Link>
      </div>
    </div>
  );
}

export default Service5;
