import React, { useContext, useRef, useEffect } from "react";
import { Steps } from "antd";
import { ContextData } from "../../context/Context.jsx";

function ProgressSteps() {
  const { pujasteps, currentpuja } = useContext(ContextData);
  const stepsRef = useRef(null);

  useEffect(() => {
    if (stepsRef.current) {
      const currentStepElement =
        stepsRef.current.querySelectorAll(".ant-steps-item")[currentpuja];
      if (currentStepElement) {
        currentStepElement.scrollIntoView({
          behavior: "smooth",
          inline: "center",
        });
      }
    }
  }, [currentpuja]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-4 bg-[#F2E9FF] pt-5">
        <span className="px-[8%] text-[14px] text-[#000000] font-medium font-roboto">
          Book Your Puja in Just a Few Simple Steps
        </span>
        <div
          className="px-[8%] max-sm:px-[2%] flex overflow-x-auto"
          ref={stepsRef}
        >
          <Steps
            responsive={false}
            direction="horizontal"
            labelPlacement="vertical"
            current={currentpuja}
            items={pujasteps.map((step, index) => ({
              key: index,
              title: (
                <span className="text-[12px] text-[#000000] font-normal font-roboto leading-0 m-0 p-0">
                  {step.title}
                </span>
              ),
            }))}
          />
        </div>
      </div>
      <div>{pujasteps[currentpuja].content}</div>
    </div>
  );
}

export default ProgressSteps;
