import React, { useContext } from "react";
import Button from "../../button/Button";
import { ContextData } from "../../../context/Context";
import LoginAccountModal from "../../modals/LoginAccountModal";
import { Link } from "react-router-dom";
import accounticon from "../../../assets/accounticon.svg";

function Account() {
  const { setOpenloginmodal, loggedIn } = useContext(ContextData);

  const handleopen = () => {
    setOpenloginmodal(true);
  };
  return (
    <div className="flex flex-row items-center gap-4">
      {loggedIn ? (
        <Link to="/user">
          <img
            src={accounticon}
            alt="accounticon"
            className="cursor-pointer size-12 border-2 border-[#FF8213] p-2 rounded-full"
          />
        </Link>
      ) : (
        <Button
          label="Login / Create an account"
          style={{
            backgroundColor: "transparent",
            color: "black",
            borderColor: "#000000",
            paddingLeft: "8px",
            paddingRight: "8px",
          }}
          onClick={handleopen}
        />
      )}
      <LoginAccountModal />
    </div>
  );
}

export default Account;
