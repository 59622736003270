import React, { useContext } from "react";
import SearchOrder from "../../../../../components/ordersearch/SearchOrder";
import { ContextData } from "../../../../../context/Context";
import OrderCard from "../../../../../components/cards/OrderCard";

function Orders() {
  const { orderlist } = useContext(ContextData);

  return (
    <>
      <div className="flex flex-row max-md:flex-col justify-between max-md:gap-4">
        <h1 className="font-inter text-[32px] max-md:text-[24px] font-bold text-[#000000] mb-10 max-md:mb-0">
          Your Orders
        </h1>
        <SearchOrder />
      </div>
      <div className="flex flex-col gap-8">
        {orderlist.map((order) => {
          const {
            id,
            orderid,
            orderDate,
            orderAmount,
            orderPujaName,
            orderAddons,
            orderAddress,
            orderPujaTime,
            orderDuration,
          } = order;

          return (
            <div key={id}>
              <OrderCard
                {...{
                  id,
                  orderid,
                  orderDate,
                  orderAmount,
                  orderPujaName,
                  orderAddons,
                  orderAddress,
                  orderPujaTime,
                  orderDuration,
                }}
              />
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Orders;
