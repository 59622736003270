import React from "react";
import Button from "../button/Button";

function Heading({ buttonName, headingdescription }) {
  return (
    <div className="w-1/3 flex flex-col gap-8 max-lg:w-full">
      <Button
        label={buttonName}
        style={{ backgroundColor: "#F8A861", color: "black" }}
      />
      <p className="font-sans text-[26px] max-sm:text-[20px] not-italic font-bold text-[#000000]">
        {headingdescription}
      </p>
    </div>
  );
}

export default Heading;
