import React from "react";
import { Collapse } from "antd";

function FAQ() {
  const text = `
  Shri Mahalakshmi Ambabai Temple located in Kolhapur, Maharashtra is
          one of the major Shakti Peethas of India. According to the legend, two
          eyes of Goddess Sati fell at this place, since then Goddess Lakshmi
          resides here. The idol of Goddess Lakshmi enshrined in this temple
          which is more than 7000 years old is considered divine and miraculous.
          Twice a year the sun's rays reach directly at the feet of Goddess
          Lakshmi. During this period, the Kirnotsav festival is celebrated
          here.
`;
  const items = [
    {
      key: "1",
      label:
        "Maa Shaktipeeth Mahalakshmi Ambabai Temple, Kolhapur, Maharashtra",
      children: <p>{text}</p>,
    },
    {
      key: "2",
      label:
        "Maa Shaktipeeth Mahalakshmi Ambabai Temple, Kolhapur, Maharashtra",
      children: <p>{text}</p>,
    },
    {
      key: "3",
      label:
        "Maa Shaktipeeth Mahalakshmi Ambabai Temple, Kolhapur, Maharashtra",
      children: <p>{text}</p>,
    },
  ];

  return (
    <div className="mx-[8%] p-8 mt-24 max-md:mt-36 max-sm:mt-20 bg-[#ffffff] rounded-[20px]">
      <h1 className="text-[20px] font-bold font-inter text-[#00000] mb-5">
        Frequently asked Questions
      </h1>
      <Collapse accordion items={items} />
    </div>
  );
}

export default FAQ;
