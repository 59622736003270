import React from "react";
import Button from "../../../components/button/Button";

function BlogFilter() {
  return (
    <div className="px-[8%] py-10 flex flex-row justify-between max-lg:gap-4 flex-wrap">
      <Button
        label="Pooja Rituals"
        style={{
          backgroundColor: "#FFF1D0",
          color: "black",
          borderColor: "#000000",
        }}
      />
      <Button
        label="Astrology"
        style={{
          backgroundColor: "#FFF1D0",
          color: "black",
          borderColor: "#000000",
        }}
      />
      <Button
        label="Legacy vs Modernism"
        style={{
          backgroundColor: "#FFF1D0",
          color: "black",
          borderColor: "#000000",
        }}
      />
      <Button
        label="Spiritual and Meditation"
        style={{
          backgroundColor: "#FFF1D0",
          color: "black",
          borderColor: "#000000",
        }}
      />
    </div>
  );
}

export default BlogFilter;
