import React from "react";
import searchicon from "../../../assets/searchicon.svg";

function SearchBar() {
  return (
    <div className="flex flex-row gap-4 items-center border-b-[1px] border-[#FF8213] px-8 py-1 flex-1">
      <input
        type="text"
        placeholder="Search for Puja, Temple services, Read blogs and many more"
        className="w-full px-4 py-2 bg-transparent placeholder-overflow-hidden placeholder-text-ellipsis placeholder-whitespace-nowrap placeholder-text-[6px] placeholder-font-quicksand placeholder-not-italic placeholder-font-normal placeholder-leading-normal placeholder-tracking-[0.2px] focus:outline-none"
      />
      <img
        src={searchicon}
        alt="searchicon"
        className="size-10 cursor-pointer"
      />
    </div>
  );
}

export default SearchBar;
