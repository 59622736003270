import React from "react";
import shield from "../../../../../assets/grouppujaassets/shield.png";

function GroupPujaProcess() {
  let pujaProcess = [
    {
      id: "process1",
      heading: "Select Puja",
      description:
        "Choose your preferred puja package from the options listed below. Each package is designed to cater to different spiritual needs and blessings.",
      imageURL: shield,
    },
    {
      id: "process2",
      heading: "Add Offerings",
      description:
        "Enhance your puja with optional offerings such as Gau Seva (cow service), Deep Daan (lamp offering), Vastra Daan (clothes donation), and Anna Daan (food donation). These offerings increase the spiritual merit of your puja.",
      imageURL: shield,
    },
    {
      id: "process3",
      heading: "Provide Sankalp Details",
      description:
        "It is believed that chanting the Mahalakshmi Mantra 11,000 times invokes divine blessings for financial abundance and happiness. Devotees receive the grace of Maa Mahalakshmi to fulfill their desires for wealth and overall prosperity.",
      imageURL: shield,
    },
    {
      id: "process4",
      heading: "Puja Day Updates",
      description:
        "It is believed that chanting the Mahalakshmi Mantra 11,000 times invokes divine blessings for financial abundance and happiness. Devotees receive the grace of Maa Mahalakshmi to fulfill their desires for wealth and overall prosperity.",
      imageURL: shield,
    },
    {
      id: "process5",
      heading: "Puja Video & Prasad Delivery",
      description:
        "It is believed that chanting the Mahalakshmi Mantra 11,000 times invokes divine blessings for financial abundance and happiness. Devotees receive the grace of Maa Mahalakshmi to fulfill their desires for wealth and overall prosperity.",
      imageURL: shield,
    },
  ];

  return (
    <div className="px-[8%] pt-24 max-md:pt-36 max-sm:pt-20 flex flex-col">
      <h1 className="text-[20px] max-md:text-[18px] font-bold font-inter text-[#00000] mb-10">
        Puja Process
      </h1>
      <div className="grid grid-cols-2 max-md:grid-cols-1 gap-16">
        {pujaProcess.map((process) => {
          const { id, heading, description, imageURL } = process;
          return (
            <div key={id} className="flex flex-row items-start gap-4">
              <img src={imageURL} alt="shield" className="size-8" />
              <div className="flex flex-col gap-4">
                <h1 className="text-[18px] max-md:text-[16px] font-bold font-inter text-[#00000]">
                  {heading}
                </h1>

                <p className="text-[18px] max-md:text-[16px] text-justify font-normal font-inter text-[#00000]">
                  {description}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default GroupPujaProcess;
