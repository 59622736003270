import React, { useState, useEffect } from "react";
import up from "../../assets/up.svg";
import LazyLoad from "react-lazyload";

function GoToTop() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {isVisible && (
        <button
          onClick={scrollToTop}
          className="z-100 fixed bottom-20 right-4 z-100 bg-[#FF8213] text-white p-2.5 rounded-full"
          aria-label="Scroll to top"
        >
          <LazyLoad height={20}>
            <img src={up} alt="up" />
          </LazyLoad>
        </button>
      )}
    </>
  );
}

export default GoToTop;
