import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "ritividhi-web.firebaseapp.com",
  projectId: "ritividhi-web",
  storageBucket: "ritividhi-web.firebasestorage.app",
  messagingSenderId: "162002115755",
  appId: "1:162002115755:web:4f397e5a66075f03e9ebee",
  measurementId: "G-P7XB9K1WFP",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
