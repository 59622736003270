import React, { useEffect } from "react";
import { Breadcrumb, Tabs } from "antd";
import Orders from "./sections/Orders";
import Footer from "../../../../components/footer/Footer";

function OrderHistory() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const items = [
    {
      key: "1",
      label: "Orders",
      children: <Orders />,
    },
    {
      key: "2",
      label: "Cancelled Orders",
      children: <Orders />,
    },
    {
      key: "3",
      label: "Order Delivered",
      children: <Orders />,
    },
  ];

  return (
    <>
      <div className="px-[16%] max-md:px-[8%] py-5">
        <Breadcrumb
          items={[
            {
              title: (
                <a
                  href="/"
                  className="font-inter font-medium text-[14px] text-[#000000]"
                >
                  Home
                </a>
              ),
            },
            {
              title: (
                <a
                  href="/user"
                  className="font-inter font-medium text-[14px] text-[#000000]"
                >
                  Profile
                </a>
              ),
            },
            {
              title: (
                <a
                  href="/order"
                  className="font-inter font-medium text-[14px] text-[#000000]"
                >
                  Order History
                </a>
              ),
            },
          ]}
          className="mb-5"
        />
        <Tabs defaultActiveKey="1" items={items} />
      </div>
      <Footer />
    </>
  );
}

export default OrderHistory;
