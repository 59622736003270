import React, { useEffect } from "react";
import Footer from "../../../../components/footer/Footer";
import AllPujaList from "./sections/AllPujaList";

function PujaCategory() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AllPujaList />
      <Footer />
    </>
  );
}

export default PujaCategory;
