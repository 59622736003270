import React, { useContext, useState } from "react";
import { ContextData } from "../../context/Context";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

function CardSwipe() {
  const [index, setIndex] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);
  const [swipeDirection, setSwipeDirection] = useState("");
  const { cards } = useContext(ContextData);
  let touchStartX = 0;

  const handleTouchStart = (e) => {
    touchStartX = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;
    const touchDiff = touchStartX - touchEndX;

    if (touchDiff > 50 && index < cards.length - 1) {
      swipeLeft();
    } else if (touchDiff < -50 && index > 0) {
      swipeRight();
    }
  };

  const swipeLeft = () => {
    setSwipeDirection("left");
    setIsSwiping(true);
    setTimeout(() => {
      setIndex((prevIndex) => prevIndex + 1);
      setIsSwiping(false);
    }, 300);
  };

  const swipeRight = () => {
    setSwipeDirection("right");
    setIsSwiping(true);
    setTimeout(() => {
      setIndex((prevIndex) => prevIndex - 1);
      setIsSwiping(false);
    }, 300);
  };

  return (
    <div className="flex flex-row items-center">
      <button
        onClick={swipeRight}
        disabled={index === 0}
        className="p-2 m-2 bg-blue-500 text-white rounded-full h-min shadow-lg disabled:opacity-50"
      >
        {"<"}
      </button>
      <div className="flex justify-center items-center bg-gray-100 overflow-hidden select-none rounded-[20px]">
        <div className="relative w-72 h-full">
          {cards.slice(index + 1, index + 3).map((card, i) => (
            <motion.div
              key={i}
              className="absolute w-full h-full p-6 rounded-lg shadow-lg transition-transform duration-500 ease-in-out transform scale-95"
              style={{
                background: card.background,
                zIndex: -i,
                opacity: 0.5 - i * 0.2,
              }}
              animate={{ translateX: 3, translateY: 4 }}
            >
              <div className="text-center">
                <h2 className="text-2xl font-bold text-gray-900">
                  {cards[index].heading}
                </h2>
                <div className="flex justify-center items-center h-full">
                  <img
                    src={cards[index].imageURL}
                    alt={cards[index].heading}
                    className="size-48"
                  />
                </div>
                <p className="text-gray-600 mb-4 -mt-4">
                  {cards[index].description}
                </p>
                <Link to={cards[index].redirect}>
                  <button className="px-4 py-2 bg-blue-500 text-white rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-200 ease-in-out">
                    {cards[index].buttonlabel}
                  </button>
                </Link>
              </div>
            </motion.div>
          ))}
          <motion.div
            className="relative w-full h-full p-6 rounded-lg shadow-lg transition-transform duration-300 ease-out transform"
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            style={{
              background: cards[index].background,
              zIndex: 10,
            }}
            initial={{ x: 0 }}
            animate={{
              x: isSwiping ? (swipeDirection === "left" ? "-100%" : "100%") : 0,
              opacity: isSwiping ? 0 : 1,
              rotate: isSwiping ? (swipeDirection === "left" ? -18 : 18) : 0,
            }}
            transition={{ duration: 0.3 }}
          >
            <div className="text-center">
              <h2 className="text-2xl font-bold text-gray-900">
                {cards[index].heading}
              </h2>
              <div className="flex justify-center items-center h-full">
                <img
                  src={cards[index].imageURL}
                  alt={cards[index].heading}
                  className="size-48"
                />
              </div>
              <p className="text-gray-600 mb-4 -mt-4">
                {cards[index].description}
              </p>
              <Link to={cards[index].redirect}>
                <button className="px-4 py-2 bg-blue-500 text-white rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-200 ease-in-out">
                  {cards[index].buttonlabel}
                </button>
              </Link>
            </div>
          </motion.div>
        </div>
      </div>
      <button
        onClick={swipeLeft}
        disabled={index === cards.length - 1}
        className="p-2 m-2 bg-blue-500 text-white rounded-full h-min shadow-lg disabled:opacity-50"
      >
        {">"}
      </button>
    </div>
  );
}

export default CardSwipe;
