import React, { useContext } from "react";
import { ContextData } from "../../../../../context/Context";

function TemplePujaPackageSelection({ pujaId, packagedetail }) {
  const {
    selectedtemplemode,
    carttemplepujadata,
    setCarttemplePujaData,
    selectedcopytemplepujadetail,
    setSelectedCopyTemplePujadetail,
  } = useContext(ContextData);

  const { packageId, name, packagePrice } = packagedetail;

  const handleselectedpackage = () => {
    if (carttemplepujadata.length > 0) {
      const updatedCartPujaData = [...carttemplepujadata];
      updatedCartPujaData[0] = {
        ...updatedCartPujaData[0],
        pujas: updatedCartPujaData[0].pujas.map((puja) => ({
          ...puja,
          homePuja: packagedetail,
        })),
      };
      const updatedpackage = selectedcopytemplepujadetail.map(
        (selectedpackagedetail) => ({
          ...selectedpackagedetail,
          homePuja: updatedCartPujaData[0].pujas[0].homePuja,
        })
      );

      setSelectedCopyTemplePujadetail(updatedpackage);
      setCarttemplePujaData(updatedCartPujaData);
    }
  };

  const isSelected =
    carttemplepujadata.length > 0 &&
    carttemplepujadata[0].pujas.some(
      (puja) => puja.homePuja?.packageId === packagedetail.packageId
    );

  return (
    <>
      {selectedtemplemode ? (
        <>
          {selectedtemplemode === "Online" ? (
            <div className="w-full flex flex-col justify-between my-4 gap-4 cursor-pointer">
              <div
                key={packageId}
                className={`w-full flex flex-row justify-between items-center border-2 border-[#000000] text-[16px] font-semibold px-10 py-4 gap-10 rounded-[10px] ${
                  isSelected ? "bg-[#E2D1F9]" : "bg-[#faecdf] opacity-40"
                }`}
                onClick={handleselectedpackage}
              >
                <div>{name[0]}</div>
                <div>₹ {packagePrice}</div>
              </div>
            </div>
          ) : (
            <div className="w-full flex flex-col justify-between my-4 gap-4 cursor-pointer">
              <div
                key={packageId}
                className={`w-full flex flex-row justify-between items-center border-2 border-[#000000] text-[16px] font-semibold px-10 py-4 gap-10 rounded-[10px] ${
                  isSelected ? "bg-[#E2D1F9]" : "bg-[#faecdf] opacity-40"
                }`}
                onClick={handleselectedpackage}
              >
                <div>{name[0]}</div>
                <div>₹ {packagePrice}</div>
              </div>
            </div>
          )}
        </>
      ) : null}
    </>
  );
}

export default TemplePujaPackageSelection;
