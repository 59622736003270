import React, { useContext, useEffect, useRef } from "react";
import { message } from "antd";
import Button from "../../../../components/button/Button";
import FooterSmall from "../../../../components/footer/FooterSmall";
import ContactForm from "../../../../components/contactform/ContactForm";
import { ContextData } from "../../../../context/Context";
import Footer from "../../../../components/footer/Footer";

function GroupPujaAddress() {
  const {
    grouppujastep,
    currentgrouppuja,
    nextgroupstep,
    prevgroupstep,
    currentgrouppujadetail,
    cartgrouppujadata,
    setCartGroupPujaData,
  } = useContext(ContextData);
  const contactFormRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNextClick = () => {
    if (contactFormRef.current.validate()) {
      const formData = contactFormRef.current.getFormData();

      if (cartgrouppujadata.length > 0) {
        const updatedaddressdata = {
          ...cartgrouppujadata[0],
          deliveryAddress: formData,
        };
        setCartGroupPujaData([
          updatedaddressdata,
          ...cartgrouppujadata.slice(1),
        ]);
      }

      contactFormRef.current.resetForm();
      nextgroupstep();
    } else {
      message.error("Please fill in all required fields.");
    }
  };

  return (
    <>
      <div className="w-full">
        {currentgrouppujadetail.map((grouppuja) => {
          const { pujaId, name, imageurl } = grouppuja;

          const pujaImage = imageurl[0].toLowerCase();
          const pujaimagearray = pujaImage.split("/");
          const groupimageurl = `https://${pujaimagearray[2]}.s3.amazonaws.com/${pujaimagearray[3]}/${pujaimagearray[4]}/${pujaimagearray[5]}`;
          return (
            <div
              key={pujaId}
              className="flex flex-row max-md:gap-0 max-md:flex-col"
            >
              <div className="flex-1">
                <img
                  src={groupimageurl}
                  alt={name}
                  className="w-full h-screen border-t-4 border-r-4 border-[#795EE3] max-md:border-none max-sm:h-full"
                />
              </div>
              <div className="flex-1 h-screen overflow-auto scrollbar-hide">
                <div className="mt-5 px-10">
                  <Button
                    label="Previous"
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      borderColor: "#FF8213",
                    }}
                    onClick={() => prevgroupstep()}
                  />
                </div>
                <div className="flex flex-row max-lg:flex-col justify-between items-start mt-5 px-10">
                  <span className="font-inter text-[#000000] text-[26px] max-md:text-[22px] font-bold">
                    {name}
                  </span>
                  <div className="flex flex-row items-center gap-4 text-[#795EE3] text-[24px] max-md:text-[16px] font-bold mt-4">
                    <span>{}</span>
                  </div>
                </div>
                <hr className="bg-[#795EE3] h-1 w-full my-4" />

                <ContactForm ref={contactFormRef} />

                <div className="flex flex-row justify-around items-center pb-10 px-10">
                  {currentgrouppuja > 0 && (
                    <Button
                      label="Previous"
                      style={{
                        backgroundColor: "#FBD16E",
                        color: "black",
                        borderColor: "#FF8213",
                      }}
                      onClick={prevgroupstep}
                    />
                  )}
                  {currentgrouppuja < grouppujastep.length - 1 && (
                    <Button
                      label="Next"
                      style={{
                        backgroundColor: "#FBD16E",
                        color: "black",
                        borderColor: "#FF8213",
                      }}
                      onClick={handleNextClick}
                    />
                  )}
                  {currentgrouppuja === grouppujastep.length - 1 && (
                    <Button
                      label="Done"
                      style={{
                        backgroundColor: "#FBD16E",
                        color: "black",
                        borderColor: "#FF8213",
                      }}
                      onClick={() => message.success("Processing complete!")}
                    />
                  )}
                </div>
                <div className="block max-sm:hidden">
                  <FooterSmall />
                </div>
                <div className="hidden max-sm:block">
                  <Footer />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default GroupPujaAddress;
