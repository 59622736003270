import React, { useContext, useEffect, useRef } from "react";
import Button from "../../../components/button/Button";
import Footer from "../../../components/footer/Footer";
import { ContextData } from "../../../context/Context";
import { Link } from "react-router-dom";
import arrowleftblog from "../../../assets/arrowleftblog.svg";
import arrowrightblog from "../../../assets/arrowrightblog.svg";

function BlogView() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { blogdata, posts } = useContext(ContextData);

  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="posts">
        {posts.map((post) => (
          <div key={post.id} className="post">
            <h2>{post.title.rendered}</h2>
            <p>Date: {new Date(post.date).toLocaleDateString()}</p>
            <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
          </div>
        ))}
      </div>

      <div className="mx-[8%] my-10 relative">
        <img
          onClick={scrollLeft}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 text-white p-2 rounded-full cursor-pointer bg-[#ffffff]"
          src={arrowleftblog}
          alt="arrowleftblog"
        />
        <div
          className="flex flex-row gap-8 overflow-x-scroll whitespace-nowrap scrollbar-hide"
          ref={scrollContainerRef}
        >
          {blogdata.map((blog) => {
            const { imageurl, text } = blog;
            return (
              <Link to="/blogview">
                <div className="flex flex-col gap-0">
                  <img
                    src={imageurl}
                    alt={text}
                    className="rounded-t-[10px]"
                    loading="lazy"
                  />
                  <span className="w-full flex justify-center bg-[#FFD981] py-1 px-4 rounded-b-[10px]">
                    {text}
                  </span>
                </div>
              </Link>
            );
          })}
          <div className="flex justify-center items-center w-full">
            <Link to="/blog" className="mr-16">
              <Button
                label="View All"
                style={{
                  backgroundColor: "transparent",
                  color: "black",
                  borderColor: "#000000",
                }}
              />
            </Link>
          </div>
        </div>

        <img
          onClick={scrollRight}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 text-white p-2 rounded-full cursor-pointer bg-[#ffffff]"
          src={arrowrightblog}
          alt="arrowrightblog"
        />
      </div>
      <Footer />
    </>
  );
}

export default BlogView;
