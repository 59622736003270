import React from "react";
import LazyLoad from "react-lazyload";
import templeservice from "../../assets/landingpageicons/templeservice.gif";
import Button from "../button/Button";
import { Link } from "react-router-dom";

function Service2() {
  return (
    <div
      className="border border-2 border-[#B8F3DE] flex flex-col gap-4 rounded-[20px] px-8 py-4"
      style={{
        background: "linear-gradient(180deg, #FFF 20.5%, #EAF5F1 43.5%)",
      }}
    >
      <h1 className="text-[28px] font-sans non-italic font-bold text-[#000000]">
        Temple Services
      </h1>
      <p className="text-[16px] font-sans non-italic font-normal text-[#000000] mb-4">
        Connect with the divine from anywhere. Book poojas at your chosen temple
        and receive blessings, along with sacred prasad.
      </p>
      <Link to="/product">
        <Button
          label="Explore"
          style={{
            backgroundColor: "#FFF1D0",
            color: "black",
            borderColor: "#000000",
          }}
        />
      </Link>
      <LazyLoad height={350}>
        <img
          src={templeservice}
          alt="templeservice"
          className="h-[350px] w-full self-center scale-[1.3]"
        />
      </LazyLoad>
    </div>
  );
}

export default Service2;
