import React from "react";
import templeactualimage from "../../../../../assets/disposableassets/templeactualimage.png";

function TempleDescription() {
  return (
    <div className="px-[8%] pt-24 max-md:pt-36 max-sm:pt-20 flex flex-col justify-between">
      <h1 className="text-[20px] max-md:text-[18px] font-bold font-inter text-[#00000] mb-10">
        Maa Shaktipeeth Mahalakshmi Ambabai Temple, Kolhapur, Maharashtra
      </h1>
      <div className="flex flex-row max-md:flex-col gap-8 rounded-[10px]">
        <img
          src={templeactualimage}
          alt="templeactualimage"
          className="w-1/2 rounded-[10px] max-md:w-full"
        />
        <div className="text-[18px] max-md:text-[16px] text-justify font-normal font-inter text-[#00000]">
          Shri Mahalakshmi Ambabai Temple located in Kolhapur, Maharashtra is
          one of the major Shakti Peethas of India. According to the legend, two
          eyes of Goddess Sati fell at this place, since then Goddess Lakshmi
          resides here. The idol of Goddess Lakshmi enshrined in this temple
          which is more than 7000 years old is considered divine and miraculous.
          Twice a year the sun's rays reach directly at the feet of Goddess
          Lakshmi. During this period, the Kirnotsav festival is celebrated
          here.
        </div>
      </div>
    </div>
  );
}

export default TempleDescription;
