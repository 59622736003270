import React from "react";
import SearchBar from "../../../../../components/searchFilter/searchFilterComponents/SearchBar";

function SearchCategory() {
  return (
    <div className="px-[8%] py-5 bg-[#FFF2D4] border border-[#FFE3A2] flex flex-row max-md:flex-col gap-8">
      <SearchBar />
      <div className="flex flex-row max-sm:flex-col justify-between gap-8 flex-1">
        <div>
          <select
            name="category"
            id="category"
            className="bg-[#FF8213] text-white px-6 py-3 rounded-[40px]"
          >
            <option value="category">Category</option>
            <option value="category">Category1</option>
            <option value="category">Category2</option>
            <option value="category">Category3</option>
          </select>
        </div>

        <div>
          <select
            name="category"
            id="category"
            className="bg-transparent text-black px-6 py-3 rounded-[40px] border border-[#000000]"
          >
            <option value="category">Sub Category</option>
            <option value="category">Sub Category1</option>
            <option value="category">Sub Category2</option>
            <option value="category">Sub Category3</option>
          </select>
        </div>

        <div>
          <select
            name="category"
            id="category"
            className="bg-transparent text-black px-6 py-3 rounded-[40px] border border-[#000000]"
          >
            <option value="category">Events</option>
            <option value="category">Events1</option>
            <option value="category">Events2</option>
            <option value="category">Events3</option>
          </select>
        </div>
      </div>
    </div>
  );
}

export default SearchCategory;
